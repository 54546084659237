<template>
    <div class="meeting-detail" :class="isActive ? '' : 'hidden'">
        <h1>Confirm meeting details with {{ attendee.first_name }}</h1>

        <div class="heading">Existing meeting details</div>
        <div class="details-container">
            <div class="d-flex-row">
                <div class="icon">
                    <v-icon size="20">mdi-map-marker</v-icon>
                </div>
                <div class="right">
                    {{ existingMeeting.location.location_name }}
                </div>
            </div>
            <div class="d-flex-row">
                <div class="icon">
                    <v-icon size="20">mdi-clock-outline</v-icon>
                </div>
                <div class="right">
                    {{ existingMeeting.start_time | formatTime("ddd D MMM") }},
                    {{ existingMeeting.start_time | formatTime("hh:mm") }} -
                    {{ existingMeeting.end_time | formatTime("hh:mma") }}
                </div>
            </div>
        </div>

        <div class="heading">Proposed changes</div>
        <div class="details-container">
            <div class="d-flex-row">
                <div class="icon">
                    <v-icon size="20">mdi-map-marker</v-icon>
                </div>
                <div class="right">
                    {{ location.location_name }}
                </div>
            </div>
            <div class="d-flex-row">
                <div class="icon">
                    <v-icon size="20">mdi-clock-outline</v-icon>
                </div>
                <div class="right">
                    {{ startTime | formatTime("ddd D MMM") }},
                    {{ startTime | formatTime("hh:mm") }} - {{ endTime | formatTime("hh:mma") }}
                </div>
            </div>
        </div>

        <div class="button full-width" @click="sendRequest()">
            <pulse-loader :loading="isSaving" color="white"></pulse-loader>
            <span v-if="!isSaving">Request Changes</span>
        </div>
    </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
    components: { PulseLoader },
    props: [
        "isActive",
        "startTime",
        "endTime",
        "location",
        "attendee",
        "isSaving",
        "existingMeeting",
    ],
    name: "MeetingDetail",
    methods: {
        sendRequest() {
            this.$emit("sendupdatedmeetingrequest");
        },
    },
};
</script>

<style scoped lang="scss">
@import "src/assets/_index.scss";

.meeting-detail {
    margin-top: $medium;

    &.hidden {
        display: none;
    }

    .name {
        font-weight: bold;
    }

    .d-flex-row {
        display: flex;
        flex-direction: row;
        margin-bottom: $medium;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.form-container {
    margin-top: $medium;
}

.details-container {
    margin-top: $medium;
    background: #f3f3f3;
    padding: $gutter-width;
    border-radius: 10px;
}

.input-label {
    margin-top: $medium;
}

.icon {
    flex: 0 0 2.5rem;
}

.input-group textarea {
    padding: 8px;
    min-height: 5rem;
}

.right {
    flex: 1;
}

sup {
    top: -0.1em;
}

.heading {
    font-size: 14px;
    font-weight: bold;
    margin-top: $large;
}

.v-spinner {
    display: none;
}
</style>
