<template>
    <button class="wayfinding-button" @click="handleWayfindingClick()">
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: "WayfindingButton",
    props: {
        templateId: {
            type: Number,
            required: true,
        },
        moduleId: {
            type: Number,
            required: true,
        },
    },
    methods: {
        handleWayfindingClick() {
            if (this.templateId === 0 || this.moduleId === 0) {
                return;
            }

            const o = {
                type: "content",
                templateId: 710,
                moduleId: 1,
                arguments: {
                    floorplanArguments: {
                        destination: {
                            templateId: this.templateId,
                            moduleId: this.moduleId,
                        },
                    },
                },
            };

            console.log(o); // eslint-disable-line no-console

            this.execute("callback:" + JSON.stringify(o));
        },
        execute(url) {
            let iframe = document.createElement("IFRAME");

            const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
            if (iOS) {
                url = url.replace(/\\/g, "%5C");
            }
            iframe.setAttribute("src", url);
            document.documentElement.appendChild(iframe);
            iframe.parentNode.removeChild(iframe);
            iframe = null;

            try {
                window.parent.postMessage(url, "*");
            } catch (e) {
                // can't be empty
            }
        },
    },
};
</script>
