<template>
    <div class="session-block overlay" :class="showSessionBlockCard ? '' : 'hidden'">
        <div class="inner">
            <div class="header">
                <div class="heading fill">Create new unavilability</div>
                <div class="close-btn" @click="closeAndReset()">
                    Cancel
                    <v-icon size="22">mdi-close</v-icon>
                </div>
            </div>
            <div class="sub-heading">
                Meetings cannot be requested during times marked as unavailable
            </div>

            <div class="form-container">
                <div class="input-group">
                    <label class="input-label">Start Time?<sup>*</sup></label>
                    <input type="time" v-model="startTime" />
                </div>
                <div class="input-group">
                    <label class="input-label">End Time?<sup>*</sup></label>
                    <input type="time" v-model="endTime" />
                </div>
            </div>

            <div
                class="button full-width"
                :class="!isComplete ? 'disabled' : ''"
                v-on:click="createSession()"
            >
                <pulse-loader :loading="isSavingMeeting" color="white"></pulse-loader>
                <span v-if="!isSavingMeeting">Save</span>
            </div>
        </div>
    </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
    components: { PulseLoader },
    name: "SessionBlock",
    props: {
        showSessionBlockCard: Boolean,
        selectedDate: String,
    },
    data() {
        return {
            startTime: "",
            endTime: "",
            isSavingMeeting: false,
            hasFailedSaving: false,
            componentKey: 0,
            sessionId: null,
            loading: false,
        };
    },
    methods: {
        isDateValid: function () {
            const firstDate = new Date(this.selectedDate + " " + this.startTime);
            const secondDate = new Date(this.selectedDate + " " + this.endTime);
            return secondDate > firstDate;
        },
        closeAndReset() {
            this.startTime = "";
            this.endTime = "";

            this.showSessionBlockCard = !this.showSessionBlockCard;
            this.$emit("update:sessionBlockCard", this.showSessionBlockCard);

            this.componentKey += 1;
        },
        createSession: async function () {
            window.console.log("Here");
            if (this.isSavingMeeting) {
                return false;
            }

            this.isSavingMeeting = true;

            var axios = require("axios");

            try {
                var response = await axios.post(
                    this.$root.baseApi + "/attendee/session/new",
                    {
                        sessions: [
                            {
                                start_time: this.selectedDate + " " + this.startTime + ":00",
                                end_time: this.selectedDate + " " + this.endTime + ":00",
                                session_name: "Unavailable",
                            },
                        ],
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Bearer " + this.$root.token,
                        },
                    }
                );

                if (response.status === 200 && response.data.data.sessions.length > 0) {
                    this.sessionId = response.data.data.sessions[0].session_id;
                } else {
                    this.hasFailedSaving = true;
                }
                this.isSavingMeeting = false;
            } catch (e) {
                this.isSavingMeeting = false;
                this.hasFailedSaving = true;
            }
        },
    },
    watch: {
        sessionId: function () {
            this.$emit("update:completed", true);
            this.closeAndReset();
        },
    },
    computed: {
        isComplete() {
            return (
                this.startTime != "" &&
                this.endTime != "" &&
                this.selectedDate != null &&
                this.isDateValid()
            );
        },
    },
};
</script>

<style scoped lang="scss">
@import "src/assets/_index.scss";

.inner {
    flex: 1;
    padding: $gutter-width;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

.session-block {
    padding-bottom: 300px;
    overflow-y: scroll;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    background: white;
    z-index: 100;

    .sub-heading {
        margin-top: 1rem;
    }

    &.hidden {
        height: 0 !important;
        overflow: hidden !important;
        padding: 0 !important;
    }

    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.heading {
    font-size: 14px;
    font-weight: bold;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .close-btn {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row;
        font-size: 14px;
        font-weight: bold;
        opacity: 0.6;
    }
}

input[type="time"] {
    width: 100%;
    min-height: 40px;
    padding: 0px 8px;
    border: 1px solid #8c8c8c;
    border-radius: 4px;
    background-color: #ffffff;
    background-size: cover;
    font-size: 14px;
    color: #121212;
    -webkit-text-decoration: none solid #121212;
    text-decoration: none solid #121212;
    margin-bottom: 1.5rem;
}
</style>
