<template>
    <div class="profile-card" @click="goToAttendee()">
        <div class="img">
            <img v-if="attendee.profile_image" v-lazy="attendee.profile_image" />
            <div v-else class="template-image">
                <span class="icon">
                    <v-icon size="30">mdi-account-outline</v-icon>
                </span>
            </div>
        </div>
        <div class="right-content">
            <div class="name">{{ attendee.first_name }} {{ attendee.last_name }}</div>
            <div class="subheading">{{ getPositionString }}</div>

            <div class="button-container">
                <div @click.stop="decline()" class="button outline">Decline</div>
                <div v-if="numberOfAttendees < capacity" @click.stop="accept()" class="button">
                    Accept
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AttendeeRequest",
    props: ["attendee", "capacity", "numberOfAttendees"],
    computed: {
        getPositionString() {
            let returnString = "";
            if (this.attendee.position) {
                returnString += this.attendee.position.trim();
            }

            if (this.attendee.organisation) {
                if (returnString.length > 0) {
                    returnString += ", ";
                }

                returnString += this.attendee.organisation.trim();
            }

            return returnString;
        },
    },
    methods: {
        goToAttendee() {
            let queryParams = new URLSearchParams({
                ...this.$route.query,
            });

            let path = `/dashboard/profile/${this.attendee.profile_id}`;
            let fullName = `${this.attendee.first_name} ${this.attendee.last_name}`;
            if (this.$root.isApp) {
                this.$root.entegyCallbacks.changeRoute(path, fullName, queryParams);
                return;
            }

            this.$router.push(path);
        },
        accept() {
            let attendee = this.attendee;
            this.$emit("accepted", attendee);
        },
        decline() {
            let attendee = this.attendee;
            this.$emit("declined", attendee);
        },
    },
};
</script>

<style scoped lang="scss">
@import "src/assets/_index.scss";

.profile-card {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    min-height: 5.5rem;
    margin-bottom: $medium;

    .img {
        flex: 0 0 4.2rem;
        height: 4.2rem;
        margin-right: $medium;

        img {
            width: 100%;
        }
    }

    .right-content {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: flex-start;
        border-bottom: 1px solid rgba(102, 102, 102, 0.2);
    }

    .name {
        font-weight: bold;
    }

    .title {
        font-size: 14px;
    }

    .template-image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: #dadada;
    }
}

.button-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex: 1 1 auto;
    margin: 18px 0 18px 0;

    .button {
        flex: 1 0 auto;
        padding: 5px $medium;
        font-size: 14px;
        height: 2rem;
        margin-top: 0;

        &:last-of-type {
            margin-left: $small;
        }
    }
}
</style>
