export default class EntegyApi {
    constructor(frontendBase) {
        this.frontendBase = frontendBase;
    }

    withModuleId(moduleId) {
        this.moduleId = moduleId;

        return this;
    }

    execute(url) {
        var iframe = document.createElement("IFRAME");

        var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        if (iOS) {
            url = url.replace(/\\/g, "%5C");
        }
        iframe.setAttribute("src", url);
        document.documentElement.appendChild(iframe);
        iframe.parentNode.removeChild(iframe);
        iframe = null;

        try {
            window.parent.postMessage(url, "*");
        } catch (e) {
            window.console.log("");
        }
    }

    changeRoute(urlPath, pageTitle, urlParams, openExternal) {
        let newUrlPath = `${this.frontendBase}${urlPath}`;

        if (openExternal) {
            let urlWithParams = `${this.frontendBase}${urlPath}?${urlParams.toString()}`;
            this.openExternal(urlWithParams);

            return;
        }

        this.goToPlugin(451, this.moduleId, {
            pluginData: newUrlPath,
            additionalQueryString: Object.fromEntries(urlParams),
            title: pageTitle,
        });
    }

    openExternal(urlPath, title) {
        var o = {
            type: "externalweb",
            title: title,
            url: urlPath,
        };

        this.execute("callback:" + JSON.stringify(o));
    }

    goProfilePlugin(id) {
        var url = "http://secure.entegy.com.au/path/to/url.php?profileId=" + id;

        var o = {
            type: "embeddedweb",
            title: "Profile View",
            url: url,
        };

        this.execute("callback:" + JSON.stringify(o));
    }

    profileGenericNative(profileId) {
        var o = {
            type: "profilegeneric",
            profileId: profileId,
            forceNative: "1",
        };

        this.execute("callback:" + JSON.stringify(o));
    }

    profileGenericPlugin(profileId) {
        var o = {
            type: "profilegeneric",
            profileId: profileId,
            forceNative: "0",
        };

        this.execute("callback:" + JSON.stringify(o));
    }

    refresh() {
        var o = {
            type: "refresh",
        };

        this.execute("callback:" + JSON.stringify(o));
    }

    goToContent(template, module) {
        var o = {
            type: "content",
            templateId: template,
            moduleId: module,
        };

        this.execute("callback:" + JSON.stringify(o));
    }

    callNumber(phoneNumber) {
        var o = {
            type: "callnumber",
            phonenumber: phoneNumber,
        };

        this.execute("callback:" + JSON.stringify(o));
    }

    sendEmail(email) {
        var o = {
            type: "email",
            email: email,
        };

        this.execute("callback:" + JSON.stringify(o));
    }

    goToMessaging(publicProfileId) {
        var o = {
            type: "messagethread",
            publicprofileid: publicProfileId,
        };

        this.execute("callback:" + JSON.stringify(o));
    }

    viewOnMap(latitude, longitude) {
        var o = {
            type: "showmap",
            latitude: latitude,
            longitude: longitude,
        };

        this.execute("callback:" + JSON.stringify(o));
    }

    viewDirections(toLat, toLong, fromLat, fromLong) {
        var o = {
            type: "showdirections",
            tolatitude: toLat,
            tolongitude: toLong,
            fromlatitude: fromLat,
            fromlongitude: fromLong,
        };

        this.execute("callback:" + JSON.stringify(o));
    }

    goToPlugin(template, module, args) {
        var o = {
            type: "content",
            arguments: args,
            templateId: template,
            moduleId: module,
        };

        this.execute("callback:" + JSON.stringify(o));
    }

    viewImage(url) {
        var o = {
            type: "viewimage",
            url: url,
        };

        this.execute("callback:" + JSON.stringify(o));
    }

    viewDynamicPopupText(title, body, targetTemplateId, targetModuleId, args) {
        var o = {
            type: "nativepopup",
            data: {
                title: title,
                data: body,
                notificationType: 1,
                targetTemplateId: targetTemplateId,
                targetModuleId: targetModuleId,
            },
            arguments: args,
        };

        this.execute("callback:" + JSON.stringify(o));
    }

    viewDynamicPopupImage(title, imageUrl, targetTemplateId, targetModuleId, args) {
        var o = {
            type: "nativepopup",
            data: {
                title: title,
                data: imageUrl,
                notificationType: 2,
                targetTemplateId: targetTemplateId,
                targetModuleId: targetModuleId,
            },
            arguments: args,
        };

        this.execute("callback:" + JSON.stringify(o));
    }

    navigateBack(toRoot) {
        if (toRoot) {
            this.execute('callback:{"type":"navigateback","toroot":1}');
        } else {
            this.execute('callback:{"type":"navigateback"}');
        }
    }

    startNotes(key, title) {
        var o = {
            type: "pluginNotes",
            notesKey: key,
            title: title,
        };

        this.execute("callback:" + JSON.stringify(o));
    }

    interactiveFloorplansStart(template, module) {
        var o = {
            type: "content",
            templateId: 710,
            moduleId: 1,
            arguments: {
                floorplanArguments: {
                    currentLocation: {
                        templateId: template,
                        moduleId: module,
                    },
                },
            },
        };

        this.execute("callback:" + JSON.stringify(o));
    }

    interactiveFloorplansEnd(template, module) {
        var o = {
            type: "content",
            templateId: 710,
            moduleId: 1,
            arguments: {
                floorplanArguments: {
                    destination: {
                        templateId: template,
                        moduleId: module,
                    },
                },
            },
        };

        this.this.execute("callback:" + JSON.stringify(o));
    }

    interactiveFloorplansDirections(startTemplate, startModule, endTemplate, endModule) {
        var o = {
            type: "content",
            templateId: 710,
            moduleId: 1,
            arguments: {
                floorplanArguments: {
                    currentLocation: {
                        templateId: startTemplate,
                        moduleId: startModule,
                    },
                    destination: {
                        templateId: endTemplate,
                        moduleId: endModule,
                    },
                },
            },
        };

        this.this.execute("callback:" + JSON.stringify(o));
    }

    // async getStorageItem(key) {
    //    var i = { type: 'getKey', key: key, callbackFunction: "receiveEntegyState" };
    //
    //    window.registerAwait(key);
    //    alert(i);
    //
    //    this.execute('callback:' + JSON.stringify(i));
    //
    //    return new Promise(function check(resolve) {
    //       if (!window.entegyState[0].value) {
    //          let entegyResponse = null;
    //
    //          try {
    //             entegyResponse = JSON.parse(window.entegyState[0].value);
    //          } catch (e) {
    //             entegyResponse = window.entegyState[0].value;
    //          }
    //
    //          window.entegyState = [];
    //
    //          if (entegyResponse === "") {
    //             entegyResponse = null;
    //          }
    //
    //          return resolve(entegyResponse);
    //       }
    //       setTimeout(() => check(resolve), 500);
    //    });
    // }
    //
    // setStorageItem(key, value) {
    //    var i = { type: 'setKey', key: key, value: value };
    //    this.execute('callback:' + JSON.stringify(i));
    // }
}
