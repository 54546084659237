<template>
    <div class="select-room" :class="isActive ? '' : 'hidden'">
        <h1>Choose a discussion topic</h1>
        <div class="inner">
            <Interest
                v-for="interest in interests"
                :interest="interest"
                :key="interest.interest_id"
                @selected="updateInterest"
            ></Interest>
        </div>
    </div>
</template>

<script>
import Interest from "../Onboarding/Interest";
export default {
    props: ["isActive"],
    name: "SelectInterest",
    components: { Interest },
    data() {
        return {
            interests: this.$root.event.interests,
            loading: false,
            failed: false,
        };
    },
    methods: {
        updateInterest: function (data) {
            this.$emit("selectedinterest", data.interest);
        },
    },
};
</script>

<style scoped lang="scss">
@import "src/assets/_index.scss";

.select-room {
    margin-top: $medium;

    &.hidden {
        display: none;
    }
}

.location {
    &:first-child {
        margin-top: $large;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    min-height: 3.3rem;
    padding: $small $medium;
    border: 1px solid #8c8c8c;
    border-radius: 8px;
    margin-top: $medium;
    font-size: 14px;

    .select-btn {
        height: 24px;
        fill: #121212;
    }
}

.inner {
    margin-top: $large;
}
</style>
