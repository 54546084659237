<template>
    <div class="session-block overlay" :class="showFilter ? '' : 'hidden'">
        <div class="inner">
            <div class="header">
                <div class="heading fill">Filter attendees</div>
                <div class="close-btn" @click="close()">
                    Cancel
                    <v-icon size="22">mdi-close</v-icon>
                </div>
            </div>

            <div class="form-container">
                <div class="input-group">
                    <label class="input-label">Attendee Type</label>
                    <v-select v-model="typeFilterVal" :options="availableTypes" multiple />
                </div>
            </div>

            <div class="form-container">
                <div class="input-group">
                    <label class="input-label">Attendee Interests</label>
                    <v-select v-model="interestsFilterVal" :options="availableInterests" multiple />
                </div>
            </div>

            <div class="button full-width" v-on:click="applyFilter">
                <span>Apply Filter</span>
            </div>

            <div class="button full-width" v-on:click="clearFilter">
                <span>Clear Filter</span>
            </div>
        </div>
    </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
    components: { vSelect },
    name: "ProfileFilter",
    props: {
        showFilter: Boolean,
        typeFilter: Array,
        interestsFilter: Array,
        availableTypes: Array,
    },
    data() {
        return {
            componentKey: 0,
            interestsFilterVal: [],
            typeFilterVal: [],
        };
    },
    methods: {
        clearFilter() {
            this.interestsFilterVal = [];
            this.typeFilterVal = [];
            this.applyFilter();
        },
        applyFilter() {
            this.$emit("update:interestsFilter", this.interestsFilterVal);
            this.$emit("update:typeFilter", this.typeFilterVal);

            this.close();
        },
        close() {
            this.$emit("update:showFilter", this.showFilter);

            this.componentKey += 1;
        },
    },
    computed: {
        availableInterests: function () {
            return this.$root.event.interests.map(function (interest) {
                return { label: interest.interest_name, interest: interest };
            });
        },
    },
    mounted: function () {
        this.interestsFilterVal = this.interestsFilter;
        this.typeFilterVal = this.typeFilter;
    },
};
</script>

<style scoped lang="scss">
@import "src/assets/_index.scss";

@function colour($color-name) {
    @return var(--colour-#{$color-name});
}

.inner {
    flex: 1;
    padding: $gutter-width;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

.session-block {
    padding-bottom: 300px;
    overflow-y: scroll;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    background: white;
    z-index: 100;

    .sub-heading {
        margin-top: 1rem;
    }

    &.hidden {
        height: 0 !important;
        overflow: hidden !important;
        padding: 0 !important;
    }

    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.heading {
    font-size: 14px;
    font-weight: bold;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .close-btn {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row;
        font-size: 14px;
        font-weight: bold;
        opacity: 0.6;
    }
}

input[type="time"] {
    width: 100%;
    min-height: 40px;
    padding: 0px 8px;
    border: 1px solid #8c8c8c;
    border-radius: 4px;
    background-color: #ffffff;
    background-size: cover;
    font-size: 14px;
    color: #121212;
    -webkit-text-decoration: none solid #121212;
    text-decoration: none solid #121212;
    margin-bottom: 1.5rem;
}
</style>
