<template>
    <div class="wrapper">
        <h1>Sorry, we're not able to find your attendee information.</h1>
    </div>
</template>

<script>
export default {
    name: "Unauthenticated",
};
</script>

<style scoped lang="scss">
@import "src/assets/_index.scss";
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
</style>
