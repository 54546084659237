import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

<template>
    <div class="outer">
        <div class="wrapper-inner" v-if="loading">
            <pulse-loader :loading="loading" :color="spinnerColour"></pulse-loader>
        </div>
        <router-view v-if="!loading"></router-view>
    </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
    name: "Layout",
    data: function () {
        return {
            loading: true,
            failed: false,
            eventCode: null,
            internalReference: null,
            isAuthenticated: false,
            spinnerColour: "#333",
        };
    },

    components: { PulseLoader },

    created() {
        this.eventCode = this.$route.query.eventCode;
        this.internalReference = this.$route.query.internalReference;

        if (this.$route.query.moduleId) {
            this.$root.entegyCallbacks = this.$root.entegyCallbacks.withModuleId(
                this.$route.query.moduleId
            );
        }
    },
    methods: {
        authenticate: async function () {
            var vm = this;
            var axios = require("axios");

            try {
                var response = await axios.post(this.$root.baseApi + "/authorisation", {
                    internal_reference: vm.$data.internalReference,
                    event_name: vm.$data.eventCode,
                });

                if (response.status == 200 && response.data.token) {
                    vm.$root.internalReference = vm.$data.internalReference;
                    vm.$root.eventCode = vm.$data.eventCode;
                    vm.$root.token = response.data.token;
                    vm.isAuthenticated = true;
                } else {
                    vm.failed = true;
                }
            } catch (e) {
                vm.failed = true;
            }
        },
        getEventInformation: async function () {
            if (!this.isAuthenticated) {
                this.failed = true;
                return;
            }
            var axios = require("axios");

            try {
                var response = await axios.post(
                    this.$root.baseApi + "/event/get",
                    {},
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Bearer " + this.$root.token,
                        },
                    }
                );

                if (response.status == 200 && response.data.data) {
                    this.$root.event = response.data.data;
                } else {
                    this.failed = true;
                }
            } catch (e) {
                this.failed = true;
            }
        },
        getUserInformation: async function () {
            if (!this.isAuthenticated) {
                this.failed = true;
                return;
            }

            var axios = require("axios");

            try {
                var response = await axios.post(
                    this.$root.baseApi + "/attendee/me",
                    {},
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Bearer " + this.$root.token,
                        },
                    }
                );

                if (response.status == 200 && response.data.data) {
                    this.$root.attendee = response.data.data;
                } else {
                    this.failed = true;
                }
            } catch (e) {
                this.failed = true;
            }
        },

        shouldOnboard: function () {
            let attendeeExists = this.$root.attendee !== undefined && this.$root.attendee !== null;
            let hasAcceptedTerms =
                this.$root.attendee && this.$root.attendee.configuration.accepted_terms === true;

            return !(attendeeExists && hasAcceptedTerms);
        },

        goToOnboarding: function () {
            if (this.failed) {
                this.$router.push({
                    path: "/unauthenticated",
                });
                return;
            }

            this.$router.push({
                path: "/dashboard/onboarding",
            });
        },
        saveState: function () {
            if (!this.$root.isApp) {
                return;
            }

            this.$root.entegyCallbacks.setStorageItem("event", this.$root.event);
            this.$root.entegyCallbacks.setStorageItem("token", this.$root.token);
            this.$root.entegyCallbacks.setStorageItem("attendee", this.$root.attendee);
        },
        getState: async function () {
            if (!this.$root.isApp) {
                return;
            }

            this.$root.event = await this.$root.entegyCallbacks.getStorageItem("event");
            this.$root.token = await this.$root.entegyCallbacks.getStorageItem("token");
            this.$root.attendee = await this.$root.entegyCallbacks.getStorageItem("attendee");
        },
        shouldReload() {
            return !this.$root.event || this.$root.token || this.$root.attendee;
        },
    },
    mounted: async function () {
        //  await this.getState();

        // if (this.shouldReload()) {
        await this.authenticate();
        await Promise.all([this.getEventInformation(), this.getUserInformation()]);
        // this.saveState();
        // }

        this.loading = false;

        if (this.shouldOnboard()) {
            this.goToOnboarding();
        }
    },
};
</script>

<style scoped>
.outer {
    width: 100vw;
    display: flex;
    flex-direction: column;
    height: 100vh;
}
.v-spinner {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: 1s all ease-in-out;
    z-index: 10000;
    background: white;
}
</style>
