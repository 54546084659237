<template>
    <div class="wrapper-inner">
        <div class="profile-detail" v-if="!loading && profile">
            <div class="profile-detail__image-container">
                <img
                    class="profile-detail__image"
                    v-bind:style="{
                        background:
                            'linear-gradient(to bottom, transparent 0%, #333 100%), url(' +
                            profile.profile_image +
                            ')',
                    }"
                />
                <div class="inner">
                    <div class="profile-detail__name">
                        {{ profile.first_name }} <br />{{ profile.last_name }}
                    </div>
                    <div class="profile-detail__position">
                        {{ getPositionString(profile) }} | {{ profile.type }}
                    </div>
                </div>
            </div>
            <div class="profile-detail__callout">
                <div class="profile-detail__top-container">
                    <div class="button request-meeting" @click="toggleInvite()">
                        Request Meeting
                    </div>
                    <div class="match-outer">
                        <div class="heading">Match</div>
                        <div class="match-strength">{{ profile.weighting }}%</div>
                    </div>
                </div>
                <div class="profile-detail__bottom-container">
                    <div class="heading">Topic Interests</div>
                    <div class="interests">{{ topicStrings }}</div>
                </div>
            </div>
            <div class="padding-inner">
                <div class="looking-to-meet">
                    {{ profile.configuration.looking_to_meet }}
                </div>
                <div class="heading margin-top">About</div>
                <p>{{ profile.configuration.bio }}</p>
            </div>
        </div>
        <pulse-loader :loading="loading" color="#333"></pulse-loader>
        <meeting-invite :show-invite-card="showMeeting" :attendee="profile"></meeting-invite>
    </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import MeetingInvite from "../components/Invite/MeetingInvite";

export default {
    components: { MeetingInvite, PulseLoader },
    name: "ProfileDetail",
    data() {
        return {
            profileId: this.$route.params.id,
            profile: Object,
            showMeeting: false,
            loading: false,
            failed: false,
        };
    },
    methods: {
        getProfile: async function () {
            if (this.loading) {
                return false;
            }

            this.loading = true;

            var axios = require("axios");

            try {
                var response = await axios.post(
                    this.$root.baseApi + "/attendee/get",
                    {
                        profile_id: this.profileId,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Bearer " + this.$root.token,
                        },
                    }
                );

                if (response.status === 200 && response.data.data) {
                    this.profile = response.data.data;
                } else {
                    this.failed = true;
                }
                this.loading = false;
            } catch (e) {
                this.loading = false;
                this.failed = true;
            }
        },
        getPositionString(attendee) {
            let returnString = "";
            if (attendee.position) {
                returnString += attendee.position;
            }

            if (attendee.organisation) {
                if (returnString.length > 0) {
                    returnString += ", ";
                }

                returnString += attendee.organisation;
            }

            return returnString;
        },
        toggleInvite() {
            this.showMeeting = !this.showMeeting;
        },
    },
    computed: {
        topicStrings: function () {
            let returnString = "";

            this.profile.interests.forEach(function (interest, index) {
                if (index !== 0) {
                    returnString += ", ";
                }

                returnString += interest.interest_name;
            });

            return returnString;
        },
    },
    mounted() {
        document
            .querySelector(":root")
            .style.setProperty("--colour-primary", this.$root.event.settings.primary_colour);
        document
            .querySelector(":root")
            .style.setProperty("--colour-secondary", this.$root.event.settings.secondary_colour);
        this.getProfile();
    },
};
</script>

<style scoped lang="scss">
@import "src/assets/_index.scss";

.profile-detail__image-container {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 20rem;

    .profile-detail__image {
        z-index: -1;
        position: absolute;
        background-size: contain !important;
        background-attachment: fixed !important;
        background-repeat: no-repeat !important;
        width: 100%;
        height: 100%;
    }

    .inner {
        flex: 1;
        padding: $gutter-width;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
    }

    .profile-detail__name {
        font-size: $large;
        font-weight: bold;
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
        color: white;
    }

    .profile-detail__position {
        color: white;
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    }
}

.v-spinner {
    flex: 1;
    height: 100vh;
    width: 100%;
}

.profile-detail__callout {
    padding: $gutter-width;
    background: #dadada;
}

.button {
    &.request-meeting {
        width: 75%;
        margin-top: 0;
    }
}

.profile-detail__top-container {
    display: flex;
    flex-direction: row;
}

.match-outer {
    flex: 1;
    text-align: right;
}

.heading {
    font-size: 14px;
    font-weight: bold;
}

.match-strength {
    font-weight: bold;
    font-size: 24px;
    color: #777;
}

.profile-detail__bottom-container {
    margin-top: $medium;

    .interests {
        margin-top: $small;
    }
}

.padding-inner {
    padding: $gutter-width;
}

.looking-to-meet {
    font-size: 24px;
}

.margin-top {
    margin-top: $medium;
}
</style>
