var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"wrapper"},[_c('router-view')],1),_c('div',{staticClass:"navigation-wrapper"},[_c('v-bottom-navigation',{model:{value:(_vm.bottomNav),callback:function ($$v) {_vm.bottomNav=$$v},expression:"bottomNav"}},[_c('v-btn',{attrs:{"value":"meetings","to":{
                    path: '/dashboard/panel/meetings',
                    query: { ..._vm.$route.query },
                }}},[_c('span',[_vm._v("Meetings")]),_c('v-icon',[_vm._v("mdi-calendar-month")])],1),_c('v-btn',{attrs:{"value":"discover","to":{
                    path: '/dashboard/panel/discover',
                    query: { ..._vm.$route.query },
                }}},[_c('span',[_vm._v("Discover")]),_c('v-icon',[_vm._v("mdi-account-multiple")])],1),(_vm.discussionEnabled)?_c('v-btn',{attrs:{"value":"discussion","to":{
                    path: '/dashboard/panel/discussion',
                    query: { ..._vm.$route.query },
                }}},[_c('span',[_vm._v("Discussion")]),_c('v-icon',[_vm._v("mdi-forum")])],1):_vm._e(),_c('v-btn',{attrs:{"value":"profile","to":{
                    path: '/dashboard/panel/profile',
                    query: { ..._vm.$route.query },
                }}},[_c('span',[_vm._v("Profile")]),_c('v-icon',[_vm._v("mdi-account-circle")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }