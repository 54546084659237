<template>
    <div class="message">
        <div class="profile-card" @click="goToAttendee()">
            <div class="img">
                <img
                    v-if="message.attendee.profile_image"
                    v-lazy="message.attendee.profile_image"
                />
                <div v-else class="template-image">
                    <span class="icon">
                        <v-icon size="30">mdi-account-outline</v-icon>
                    </span>
                </div>
            </div>
            <div class="right-info">
                <div class="name">
                    {{ message.attendee.first_name }} {{ message.attendee.last_name }}
                </div>
                <div class="time">
                    {{ currentTime | moment("D MMM") }}, at {{ currentTime | moment("hh:mma") }}
                </div>
            </div>
        </div>
        <div class="message-text">{{ message.message }}</div>
    </div>
</template>

<script>
export default {
    name: "Message",
    props: {
        message: Object,
    },
    computed: {
        currentTime: function () {
            return window.moment.utc(this.message.created_at).local().toString();
        },
    },
    methods: {
        goToAttendee() {
            let queryParams = new URLSearchParams({
                ...this.$route.query,
            });

            let path = `/dashboard/profile/${this.message.attendee.profile_id}`;
            let fullname = `${this.message.attendee.first_name} ${this.message.attendee.last_name}`;

            if (this.$root.isApp) {
                this.$root.entegyCallbacks.changeRoute(path, fullname, queryParams);
                return;
            }

            this.$router.push(path);
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "src/assets/_index.scss";
.profile-card {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .img {
        width: 3.5rem;
        margin-right: $medium;
        img {
            width: 100%;
        }
    }

    .name {
        font-weight: bold;
    }

    .time {
        padding-top: 4px;
        font-size: 12px;
        opacity: 0.6;
    }
}

.message {
    border-bottom: 1px solid #eeeeee;
    margin-top: $medium;
    padding: $small 0;
}

.message-text {
    padding-top: $small;
}

.template-image {
    min-height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: #dadada;
}
</style>
