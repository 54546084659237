<template>
    <div class="wrapper">
        <pulse-loader :loading="loading" color="#333"></pulse-loader>
        <div class="content" v-if="!loading">
            <div class="inner">
                <div class="details-container">
                    <div class="col-left">
                        {{ discussion.emoji }}
                    </div>
                    <div class="content-right">
                        <div class="discussion-title">{{ discussion.title }}</div>
                        <div>
                            <div
                                class="pill-inline"
                                v-if="
                                    discussion.is_cancelled ||
                                    isHost ||
                                    hasRequestedToJoin ||
                                    hasJoined
                                "
                            >
                                <div class="alert" v-if="discussion.is_cancelled"></div>
                                <span v-if="discussion.is_cancelled">Cancelled</span>
                                <span v-if="isHost">Discussion Host</span>
                                <span v-else-if="hasJoined">Discussion Participant</span>
                                <span v-else-if="hasRequestedToJoin">Request Pending</span>
                            </div>
                        </div>
                        <div class="discussion-interest">
                            {{ discussion.interest.interest_name }}
                        </div>
                        <div class="discussion-time">
                            {{ discussion.start_time | formatTime("ddd D MMM") }},
                            {{ discussion.start_time | formatTime("hh:mma") }} -
                            {{ discussion.end_time | formatTime("hh:mma") }}
                        </div>
                        <div class="discussion-location">
                            {{ discussion.location.location_name }}
                        </div>
                        <div class="discussion-description">{{ discussion.description }}</div>
                    </div>
                </div>

                <div class="button-container" v-if="isHost && !discussion.is_cancelled">
                    <div @click="showConfirm('cancelinvite')" class="button outline">
                        Cancel and Delete Discussion
                    </div>
                </div>

                <div
                    class="button-container"
                    v-if="!hasRequestedToJoin && !discussion.is_cancelled && canJoin"
                >
                    <div @click="showConfirm('requesttojoin')" class="button">
                        Request to Join Discussion
                    </div>
                </div>

                <div class="button-container" v-if="hasRequestedToJoin && !hasJoined">
                    <div @click="showConfirm('canceljoin')" class="button outline">
                        Cancel my Join Request
                    </div>
                </div>

                <div class="button-container" v-if="hasJoined && !isHost">
                    <div @click="showConfirm('leavediscussion')" class="button outline">
                        Leave Discussion
                    </div>
                </div>
            </div>
            <v-tabs grow center-active v-if="hasJoined">
                <v-tab>Discussion</v-tab>
                <v-tab
                    >Attendees ({{ confirmedAttendeeCount }}/{{
                        discussion.location.capacity
                    }})</v-tab
                >
                <v-tab v-if="isHost && !discussion.is_cancelled"
                    >Requests ({{ pendingAttendeeCount }})</v-tab
                >
                <v-tab-item>
                    <chat type="discussion" refresh-time="5000" :parent-id="discussionId"></chat>
                </v-tab-item>
                <v-tab-item>
                    <div class="collection">
                        <attendee
                            v-for="attendee in confirmedAttendees"
                            :attendee="attendee.attendee"
                            v-bind:key="attendee.attendee.profile_id"
                        ></attendee>
                    </div>
                </v-tab-item>
                <v-tab-item class="inner">
                    <div class="no-results" v-if="pendingAttendeeCount === 0">
                        <div class="inner">
                            <div class="icon">
                                <v-icon size="80">mdi-account-circle</v-icon>
                            </div>

                            <div class="message">No Pending Requests</div>
                        </div>
                    </div>
                    <div class="collection" v-else>
                        <AttendeeRequest
                            @accepted="acceptAttendee"
                            @declined="declineAttendee"
                            v-for="attendee in pendingAttendees"
                            :attendee="attendee.attendee"
                            v-bind:key="attendee.attendee.profile_id"
                            :capacity="discussion.location.capacity"
                            :number-of-attendees="confirmedAttendeeCount"
                        ></AttendeeRequest>
                    </div>
                </v-tab-item>
            </v-tabs>
            <v-dialog />
        </div>
    </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import Chat from "../../components/Chat/Chat";
import Attendee from "../../components/Attendee";
import AttendeeRequest from "../../components/AttendeeRequest";

export default {
    name: "DiscussionDetail",
    components: { AttendeeRequest, Attendee, Chat, PulseLoader },
    data() {
        return {
            discussionId: this.$route.params.id,
            discussion: {},
            loading: false,
            failed: false,
            discussionStatus: "",
            isHost: false,
            hasJoined: false,
            canJoin: false,
            hasRequestedToJoin: false,
            pendingAttendees: [],
            confirmedAttendees: [],
        };
    },
    methods: {
        loadDiscussion: async function () {
            if (this.loading) {
                return false;
            }

            this.loading = true;

            var axios = require("axios");

            try {
                var response = await axios.post(
                    this.$root.baseApi + "/discussion/get",
                    {
                        discussion_id: this.discussionId,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Bearer " + this.$root.token,
                        },
                    }
                );

                window.console.log(response);

                if (response.status == 200 && response.data.data) {
                    this.discussion = response.data.data;
                } else {
                    this.failed = true;
                }
                this.loading = false;
            } catch (e) {
                this.loading = false;
                this.failed = true;
            }
        },
        parseAttendees() {
            let participants = JSON.parse(JSON.stringify(this.discussion.participants));
            const myParticipantId = this.$root.attendee.profile_id;
            let found = false;

            let vm = this;

            // Reset state
            vm.confirmedAttendees = [];
            vm.pendingAttendees = [];

            participants.forEach(function (participant) {
                if (participant.attendee.profile_id === myParticipantId) {
                    // is host
                    vm.isHost = participant.is_host;

                    // has been accepted
                    vm.hasJoined = participant.has_accepted;

                    found = true;
                }

                if (participant.has_accepted) {
                    vm.confirmedAttendees.push(participant);
                } else {
                    vm.pendingAttendees.push(participant);
                }
            });

            this.hasRequestedToJoin = found;
            if (!found) {
                this.hasJoined = false;
            }
        },
        showConfirm(type) {
            if (type === "cancelinvite") {
                this.$modal.show("dialog", {
                    title: "Cancel Invite",
                    text: "Are you sure you wish to cancel and delete this discussion?",
                    buttons: [
                        {
                            title: "No",
                        },
                        {
                            title: "Yes",
                            handler: () => {
                                this.cancelDiscussion();
                            },
                        },
                    ],
                });
            }

            if (type === "requesttojoin") {
                this.$modal.show("dialog", {
                    title: "Request To Join",
                    text: "Are you sure you wish to join this discussion?",
                    buttons: [
                        {
                            title: "No",
                        },
                        {
                            title: "Yes",
                            handler: () => {
                                this.joinDiscussion();
                            },
                        },
                    ],
                });
            }

            if (type === "canceljoin") {
                this.$modal.show("dialog", {
                    title: "Cancel Join Request",
                    text: "Are you sure you wish to cancel your pending join request?",
                    buttons: [
                        {
                            title: "No",
                        },
                        {
                            title: "Yes",
                            handler: () => {
                                this.leaveDiscussion();
                            },
                        },
                    ],
                });
            }

            if (type === "leavediscussion") {
                this.$modal.show("dialog", {
                    title: "Leave Discussion",
                    text: "Are you sure you wish to leave this discussion?",
                    buttons: [
                        {
                            title: "No",
                        },
                        {
                            title: "Yes",
                            handler: () => {
                                this.leaveDiscussion();
                            },
                        },
                    ],
                });
            }
        },
        async cancelDiscussion() {
            if (this.loading) {
                return false;
            }

            this.loading = true;

            var axios = require("axios");

            try {
                var response = await axios.post(
                    this.$root.baseApi + "/discussion/update",
                    {
                        discussion_id: this.discussionId,
                        is_cancelled: true,
                        start_time: this.discussion.start_time,
                        end_time: this.discussion.end_time,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Bearer " + this.$root.token,
                        },
                    }
                );

                if (response.status == 200 && response.data.data) {
                    this.discussion = response.data.data;
                } else {
                    this.failed = true;
                }
                this.loading = false;
            } catch (e) {
                this.loading = false;
                this.failed = true;
            }
        },
        async joinDiscussion() {
            if (this.loading) {
                return false;
            }

            this.loading = true;

            var axios = require("axios");

            try {
                var response = await axios.post(
                    this.$root.baseApi + "/discussion/join",
                    {
                        discussion_id: this.discussionId,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Bearer " + this.$root.token,
                        },
                    }
                );

                if (response.status == 200 && response.data.data) {
                    this.discussion = response.data.data;
                } else {
                    this.failed = true;
                }
                this.loading = false;
            } catch (e) {
                this.loading = false;
                this.failed = true;
            }
        },
        async leaveDiscussion() {
            if (this.loading) {
                return false;
            }

            this.loading = true;

            var axios = require("axios");

            try {
                var response = await axios.post(
                    this.$root.baseApi + "/discussion/leave",
                    {
                        discussion_id: this.discussionId,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Bearer " + this.$root.token,
                        },
                    }
                );

                if (response.status == 200 && response.data.data) {
                    this.discussion = response.data.data;
                } else {
                    this.failed = true;
                }
                this.loading = false;
            } catch (e) {
                this.loading = false;
                this.failed = true;
            }
        },
        async acceptAttendee(e) {
            if (this.loading) {
                return false;
            }

            window.console.log("here");
            window.console.log(e);

            this.loading = true;
            let attendee = e;

            var axios = require("axios");

            try {
                var response = await axios.post(
                    this.$root.baseApi + "/discussion/approve",
                    {
                        discussion_id: this.discussionId,
                        profile_id: attendee.profile_id,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Bearer " + this.$root.token,
                        },
                    }
                );

                if (response.status == 200 && response.data.data) {
                    this.discussion = response.data.data;
                } else {
                    this.failed = true;
                }
                this.loading = false;
            } catch (e) {
                this.loading = false;
                this.failed = true;
            }
        },
        async declineAttendee(e) {
            if (this.loading) {
                return false;
            }

            this.loading = true;
            let attendee = e;

            var axios = require("axios");

            try {
                var response = await axios.post(
                    this.$root.baseApi + "/discussion/decline",
                    {
                        discussion_id: this.discussionId,
                        profile_id: attendee.profile_id,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Bearer " + this.$root.token,
                        },
                    }
                );

                if (response.status == 200 && response.data.data) {
                    this.discussion = response.data.data;
                } else {
                    this.failed = true;
                }
                this.loading = false;
            } catch (e) {
                this.loading = false;
                this.failed = true;
            }
        },
        async canUserJoin() {
            var axios = require("axios");

            try {
                var response = await axios.post(
                    this.$root.baseApi + "/discussion/canjoin",
                    {
                        discussion_id: this.discussionId,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Bearer " + this.$root.token,
                        },
                    }
                );

                if (response.status == 200) {
                    this.canJoin = true;
                } else {
                    this.canJoin = false;
                }
            } catch (e) {
                this.canJoin = false;
            }
        },
    },
    async mounted() {
        document
            .querySelector(":root")
            .style.setProperty("--colour-primary", this.$root.event.settings.primary_colour);
        document
            .querySelector(":root")
            .style.setProperty("--colour-secondary", this.$root.event.settings.secondary_colour);
        this.canUserJoin();
        await this.loadDiscussion();
    },
    computed: {
        pendingAttendeeCount() {
            return this.pendingAttendees.length;
        },
        confirmedAttendeeCount() {
            return this.confirmedAttendees.length;
        },
    },
    watch: {
        discussion: function () {
            this.canUserJoin();
            this.parseAttendees();
        },
    },
};
</script>

<style scoped lang="scss">
@import "src/assets/_index.scss";

.discussion-title {
    font-size: 24px;
    font-weight: bold;
}

.discussion-interest {
    font-size: 14px;
    margin-top: $small;
    opacity: 0.5;
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.content-right {
    position: relative;
}

.discussion-time,
.discussion-location {
    font-size: 16px;
    font-weight: bold;
    margin-top: $small;
}

.discussion-description {
    margin-top: $small;
}

.details-container {
    display: flex;
    flex-direction: row;
}

.col-left {
    flex: 0 0 3.5rem;
    font-size: 36px;
}

.button-container {
    display: flex;

    .button {
        flex: 1 1 auto;
        width: auto;
        margin-left: $small;

        &:first-child {
            margin-left: 0;
        }
    }
}

.collection {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: scroll; /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
    margin-top: $medium;
}

.no-results {
    flex-direction: row;
    align-items: center;
    display: flex;
    justify-content: center;
    flex: 1;
    opacity: 0.6;

    .inner {
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;

        .message {
            margin-top: $medium;
            font-size: 16px;
        }
    }
}

.pill-inline {
    font-size: 11px;
    padding: 3px 5px;
    border-radius: 5px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    background: rgba(51, 51, 51, 0.2);
    margin: $small 0;

    .alert {
        width: 6px;
        height: 6px;
        background: #ff0d00;
        border-radius: 50%;
        margin-right: 4px;
    }
}
</style>
