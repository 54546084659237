<template>
    <div>
        <div class="wrapper">
            <router-view></router-view>
        </div>
        <div class="navigation-wrapper">
            <v-bottom-navigation v-model="bottomNav">
                <v-btn
                    value="meetings"
                    :to="{
                        path: '/dashboard/panel/meetings',
                        query: { ...$route.query },
                    }"
                >
                    <span>Meetings</span>
                    <v-icon>mdi-calendar-month</v-icon>
                </v-btn>

                <v-btn
                    value="discover"
                    :to="{
                        path: '/dashboard/panel/discover',
                        query: { ...$route.query },
                    }"
                >
                    <span>Discover</span>
                    <v-icon>mdi-account-multiple</v-icon>
                </v-btn>

                <v-btn
                    v-if="discussionEnabled"
                    value="discussion"
                    :to="{
                        path: '/dashboard/panel/discussion',
                        query: { ...$route.query },
                    }"
                >
                    <span>Discussion</span>
                    <v-icon>mdi-forum</v-icon>
                </v-btn>

                <v-btn
                    value="profile"
                    :to="{
                        path: '/dashboard/panel/profile',
                        query: { ...$route.query },
                    }"
                >
                    <span>Profile</span>
                    <v-icon>mdi-account-circle</v-icon>
                </v-btn>
            </v-bottom-navigation>
        </div>
    </div>
</template>

<script>
export default {
    name: "Dashboard",
    data() {
        return {
            bottomNav: "meetings",
            discussionEnabled: this.$root.event.settings.discussionEnabled,
            primaryColour: this.$root.event.settings.primary_colour,
            secondaryColour: this.$root.event.settings.secondary_colour,
        };
    },
    mounted() {
        document.querySelector(":root").style.setProperty("--colour-primary", this.primaryColour);
        document
            .querySelector(":root")
            .style.setProperty("--colour-secondary", this.secondaryColour);
    },
};
</script>

<style lang="scss">
@function colour($color-name) {
    @return var(--colour-#{$color-name});
}

.wrapper {
    height: calc(100vh - 85px);

    /* iphone X */
    @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
        height: calc(100vh - 100px);
    }

    /* iphone XR */
    @media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
        height: calc(100vh - 100px);
    }

    /* iphone XS */
    @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
        height: calc(100vh - 100px);
    }

    /* iphone XS Max */
    @media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
        height: calc(100vh - 100px);
    }
}

.navigation-wrapper {
    position: fixed !important;
    bottom: 0;

    /* iphone X */
    @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
        padding-bottom: 20px;
    }

    /* iphone XR */
    @media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
        padding-bottom: 20px;
    }

    /* iphone XS */
    @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
        padding-bottom: 20px;
    }

    /* iphone XS Max */
    @media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
        padding-bottom: 20px;
    }

    background-color: colour(primary) !important;
    width: 100vw;
}

.v-bottom-navigation {
    justify-content: space-evenly !important;
    align-items: start !important;
    background-color: colour(primary) !important;
    color: white;
    box-shadow: none !important;

    .v-btn {
        span {
            opacity: 0.6;
            font-size: 12px;
            color: white !important;
        }

        &.v-btn--active {
            span {
                opacity: 1;
                color: white !important;
            }
        }
    }
}
</style>
