<template>
    <div class="meeting-invite overlay" :class="showInviteCard ? '' : 'hidden'">
        <div class="inner">
            <div class="header">
                <div class="heading fill">Suggest Meeting Update</div>
                <div class="close-btn" @click="closeAndReset()">
                    Cancel Update
                    <v-icon size="22">mdi-close</v-icon>
                </div>
            </div>
            <SelectRoom
                @updatedroom="roomSelected"
                :location="location"
                :type="type"
                :is-update="true"
                capacity="2"
                :is-active="currentTab == 'location'"
                :key="componentKey"
                :attendee_id="$root.attendee.profile_id"
            ></SelectRoom>
            <SelectTime
                @updatedtime="timeSelected"
                :is-active="currentTab == 'time'"
                :is-update="true"
                :attendee="attendee"
                :meeting-id="meeting.meeting_id"
                :location="location"
                :type="type"
                :key="componentKey"
            ></SelectTime>
            <MeetingComfirm
                @sendupdatedmeetingrequest="updateMeeting"
                :is-active="currentTab == 'message'"
                :start-time="startTime"
                :end-time="endTime"
                :is-saving="isSavingMeeting"
                :location="location"
                :attendee="attendee"
                :existing-meeting="meeting"
                :key="componentKey"
            ></MeetingComfirm>
        </div>
    </div>
</template>

<script>
import SelectRoom from "./SelectRoom";
import SelectTime from "./SelectTime";
import MeetingComfirm from "./MeetingUpdateConfirm";

export default {
    name: "MeetingUpdate",
    components: { MeetingComfirm, SelectRoom, SelectTime },
    props: ["attendee", "showInviteCard", "meeting"],
    data() {
        return {
            currentTab: "location",
            location: {},
            type: "meeting",
            startTime: "",
            endTime: "",
            isSavingMeeting: false,
            hasFailedSaving: false,
            updatedMeeting: {},
            componentKey: 0,
        };
    },
    methods: {
        closeAndReset() {
            this.currentTab = "location";
            this.location = {};
            this.startTime = "";
            this.endTime = "";
            this.title = "";
            this.description = "";
            this.componentKey += 1;

            this.$emit("closecard");
        },
        roomSelected(e) {
            this.location = e;
            this.currentTab = "time";
        },
        timeSelected(e) {
            this.startTime = e.startTime;
            this.endTime = e.endTime;
            this.currentTab = "message";
        },
        updateMeeting: async function () {
            if (this.isSavingMeeting) {
                return false;
            }

            this.isSavingMeeting = true;

            var axios = require("axios");

            try {
                var response = await axios.post(
                    this.$root.baseApi + "/meeting/update",
                    {
                        location_id: this.location.location_id,
                        start_time: this.startTime,
                        end_time: this.endTime,
                        meeting_id: this.meeting.meeting_id,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Bearer " + this.$root.token,
                        },
                    }
                );

                if ((response.status === 200 || response.status === 201) && response.data.data) {
                    this.updatedMeeting = response.data.data;
                } else {
                    this.hasFailedSaving = true;
                }
                this.isSavingMeeting = false;
            } catch (e) {
                this.isSavingMeeting = false;
                this.hasFailedSaving = true;
            }
        },
    },
    watch: {
        meetingId: function () {
            this.handleMeetingCreated();
        },
        updatedMeeting: function () {
            this.$emit("meetingupdated", { meeting: this.updatedMeeting });
            this.closeAndReset();
        },
    },
};
</script>

<style scoped lang="scss">
@import "src/assets/_index.scss";

.inner {
    flex: 1;
    padding: $gutter-width;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

.meeting-invite {
    position: fixed;
    padding-bottom: 300px;
    overflow-y: scroll;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    background: white;
    z-index: 100;

    &.hidden {
        height: 0 !important;
        overflow: hidden !important;
        padding: 0 !important;
    }

    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.heading {
    font-size: 14px;
    font-weight: bold;
}

.margin-bottom-sm {
    margin-bottom: $small;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .close-btn {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row;
        font-size: 14px;
        font-weight: bold;
        opacity: 0.6;
    }
}

.form-container {
    margin-top: $medium;
}
</style>
