<template>
    <div class="select-room" :class="isActive ? '' : 'hidden'">
        <h1 v-if="!isUpdate">Choose a<br />meeting room</h1>
        <h1 v-else>Suggest a new<br />meeting room</h1>
        <pulse-loader :loading="loading" color="#333"></pulse-loader>

        <div class="locations-container" v-if="!loading && availableLocations.length > 0">
            <div
                class="location"
                v-for="locationObj in availableLocations"
                v-bind:key="locationObj.location_id"
            >
                <div
                    class="select-location"
                    v-bind:class="locationObj.is_virtual ? 'virtual' : ''"
                    @click="selectLocation(locationObj.location_id)"
                >
                    <div class="location-name">{{ locationObj.location_name }}</div>
                    <div v-if="type == 'discussion'" class="capacity">
                        Room capacity: {{ locationObj.capacity }}
                    </div>
                    <div v-if="locationObj.is_virtual" class="capacity">Virtual Video Meeting</div>
                </div>
                <div class="button-container">
                    <WayFinding
                        class="select-btn"
                        v-if="locationObj.template_id && locationObj.module_id"
                        :template-id="locationObj.template_id"
                        :module-id="locationObj.module_id"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M11.5397 22.351C11.57 22.3685 11.5937 22.3821 11.6105 22.3915L11.6384 22.4071C11.8613 22.5294 12.1378 22.5285 12.3608 22.4075L12.3895 22.3915C12.4063 22.3821 12.43 22.3685 12.4603 22.351C12.5207 22.316 12.607 22.265 12.7155 22.1982C12.9325 22.0646 13.2388 21.8676 13.6046 21.6091C14.3351 21.0931 15.3097 20.3274 16.2865 19.3273C18.2307 17.3368 20.25 14.3462 20.25 10.5C20.25 5.94365 16.5563 2.25 12 2.25C7.44365 2.25 3.75 5.94365 3.75 10.5C3.75 14.3462 5.76932 17.3368 7.71346 19.3273C8.69025 20.3274 9.66491 21.0931 10.3954 21.6091C10.7612 21.8676 11.0675 22.0646 11.2845 22.1982C11.393 22.265 11.4793 22.316 11.5397 22.351ZM12 13.5C13.6569 13.5 15 12.1569 15 10.5C15 8.84315 13.6569 7.5 12 7.5C10.3431 7.5 9 8.84315 9 10.5C9 12.1569 10.3431 13.5 12 13.5Z"
                                fill="#0F172A"
                            />
                        </svg>
                    </WayFinding>
                    <div
                        class="select-btn"
                        v-bind:class="locationObj.is_virtual ? 'virtual' : ''"
                        @click="selectLocation(locationObj.location_id)"
                    >
                        <svg
                            version="1.2"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            overflow="visible"
                            preserveAspectRatio="none"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                        >
                            <g>
                                <path
                                    xmlns:default="http://www.w3.org/2000/svg"
                                    d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"
                                    vector-effect="non-scaling-stroke"
                                />
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import WayFinding from "../WayFinding.vue";

export default {
    components: { PulseLoader, WayFinding },
    // props: {
    //    isActive: Boolean,
    //    capacity: String,
    //    type: String,
    //    location: Object,
    //    isUpdate: Boolean,
    //    attendee_id: String
    // },
    props: ["isActive", "capacity", "type", "location", "isUpdate", "attendee_id"],
    name: "SelectRoom",
    data() {
        return {
            availableLocations: [],
            loading: false,
            failed: false,
        };
    },
    methods: {
        getLocations: async function () {
            if (this.loading) {
                return false;
            }

            this.loading = true;

            var axios = require("axios");

            try {
                var response = await axios.post(
                    this.$root.baseApi + "/location/all",
                    {
                        type: this.type,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Bearer " + this.$root.token,
                        },
                    }
                );

                if (response.status === 200 && response.data.data.locations) {
                    // sort so virtual first
                    this.availableLocations = response.data.data.locations.sort(function (a) {
                        return a.is_virtual ? -1 : 0;
                    });
                } else {
                    this.failed = true;
                }

                // if attendee_id is not set, get it from url /profile/attendee_id
                if (typeof this.attendee_id === "undefined" || !this.attendee_id) {
                    var attendeeId = window.location.pathname.split("/").pop();
                    if (attendeeId) {
                        this.attendee_id = attendeeId;
                    }
                }

                var boothResponse = await axios.post(
                    this.$root.baseApi + "/location/booths",
                    {
                        profile_id: this.attendee_id,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Bearer " + this.$root.token,
                        },
                    }
                );

                if (boothResponse.status === 200 && boothResponse.data.data.locations) {
                    this.availableLocations = this.availableLocations.concat(
                        boothResponse.data.data.locations
                    );
                }

                this.loading = false;
            } catch (e) {
                this.loading = false;
                this.failed = true;
            }
        },
        selectLocation(locationId) {
            let location = this.availableLocations.filter(function (location) {
                return location.location_id == locationId;
            })[0];
            this.$emit("updatedroom", location);
        },
    },
    mounted() {
        this.getLocations();
    },
};
</script>

<style scoped lang="scss">
@import "src/assets/_index.scss";

.select-room {
    margin-top: $medium;

    &.hidden {
        display: none;
    }
}

.capacity {
    font-size: 12px;
    opacity: 0.6;
}

.location {
    &:first-child {
        margin-top: $large;
        border-top: none;
    }

    display: flex;
    display: flex;
    flex: auto;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    min-height: 3.3rem;
    padding-block: 20px;
    border-top: 1px solid #d6d6d6;
    font-size: 14px;
    gap: $medium;

    .select-btn {
        &:nth-child(2) {
            border-left: 1px solid #d1d5db;
        }

        display: flex;
        padding: 10px;
        align-items: flex-start;
        gap: 4px;
    }

    &.virtual {
        background: colour(secondary);
        border: none;

        .location-name,
        .capacity,
        .select-btn {
            color: white !important;
            fill: white !important;
        }
    }
}

.select-location {
    display: flex;
    flex: auto;
    justify-content: space-between;
    align-items: center;
}

.wayfinding-button {
    padding-left: 4px;
}

.button-container {
    display: flex;
    align-items: flex-start;

    border-radius: 8px;
    border: 1px solid #d1d5db;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}
</style>
