import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../views/Layout";
import Dashboard from "../views/Dashboard/Dashboard";
import OnboardingStart from "../views/Dashboard/OnboardingStart";
import OnboardingProfile from "../views/Dashboard/OnboardingProfile";
import Unauthenticated from "../views/Unauthenticated";
import OnboardingInterestsPopulate from "../views/Dashboard/OnboardingInterestsPopulate";
import Meetings from "../views/Dashboard/Panel/Meetings";
import Discover from "../views/Dashboard/Panel/Discover";
import Discussion from "../views/Dashboard/Panel/Discussion";
import Profile from "../views/Dashboard/Panel/Profile";
import ProfileDetail from "../views/ProfileDetail";
import Meeting from "../views/Dashboard/Meeting";
import DiscussionDetail from "../views/Dashboard/DiscussionDetail";
import VirtualMeeting from "@/views/Dashboard/VirtualMeeting";

Vue.use(VueRouter);

const routes = [
    {
        path: "/dashboard",
        component: Layout,
        children: [
            {
                path: "panel",
                component: Dashboard,
                children: [
                    {
                        path: "meetings",
                        component: Meetings,
                    },
                    {
                        path: "discover",
                        component: Discover,
                    },
                    {
                        path: "discussion",
                        component: Discussion,
                    },
                    {
                        path: "profile",
                        component: Profile,
                    },
                ],
            },
            {
                path: "onboarding",
                component: OnboardingStart,
            },
            {
                path: "profilepopulate",
                component: OnboardingProfile,
            },
            {
                path: "interestspopulate",
                component: OnboardingInterestsPopulate,
            },
            {
                path: "profile/:id",
                component: ProfileDetail,
            },
            {
                path: "meeting/:id",
                component: Meeting,
            },
            {
                path: "meeting/:id/virtual",
                component: VirtualMeeting,
            },
            {
                path: "discussion/:id",
                component: DiscussionDetail,
            },
        ],
    },
    {
        path: "/unauthenticated",
        component: Unauthenticated,
    },
];

const router = new VueRouter({
    mode: "history",
    routes: routes,
});

export default router;
