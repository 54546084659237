<template>
    <label class="interest" :class="isSelected ? 'selected' : ''">
        <input type="checkbox" class="interest" @click="selected" />
        {{ interest.interest_name }}
    </label>
</template>

<script>
export default {
    props: ["interest"],
    name: "Interest",
    data() {
        window.console.log(this.interest);

        let isSelected = false;
        if (this.interest.isSelected) {
            isSelected = true;
        }

        return {
            isSelected: isSelected,
        };
    },
    methods: {
        selected(evt) {
            this.isSelected = !this.isSelected;
            this.$emit("selected", {
                interest: this.interest,
                newSelection: evt.target.checked,
            });
        },
    },
};
</script>

<style scoped lang="scss">
@import "src/assets/_index.scss";

.interest {
    display: block;
    input {
        display: none;
    }

    padding: 12px;
    margin-bottom: $medium;
    border: 1px solid #8c8c8c;
    text-align: center;

    &.selected {
        background: #c7c7c7;
    }

    transition: 0.2s background-color ease-in-out;
}
</style>
