<template>
    <emoji-picker @emoji="insert" :search="search" :emoji-table="availableEmojis">
        <div
            slot="emoji-invoker"
            slot-scope="{ events: { click: clickEvent } }"
            @click.stop="clickEvent"
        >
            <div class="input-group">
                <label class="input-label">Emoji <sup>*</sup></label>
                <label class="input-label sub-label">Tap to select</label>
            </div>
            <button type="button" class="emoji">{{ selectedEmoji }}</button>
        </div>
        <div slot="emoji-picker" slot-scope="{ emojis, insert }">
            <div>
                <div>
                    <div v-for="(emojiGroup, category) in emojis" :key="category">
                        <div class="heading">{{ category }}</div>
                        <div class="emoji-wrapper">
                            <span
                                class="emoji"
                                v-for="(emoji, emojiName) in emojiGroup"
                                :key="emojiName"
                                @click="insert(emoji)"
                                :title="emojiName"
                                >{{ emoji }}</span
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </emoji-picker>
</template>

<script>
import EmojiPicker from "vue-emoji-picker";

export default {
    components: { EmojiPicker },
    name: "EmojiPickerWrapper",
    data() {
        return {
            selectedEmoji: "💬",
            search: "",
            availableEmojis: {
                "Choose an emoji": {
                    sheep: "🐑",
                    chicken: "🐔",
                    cow: "🐮",
                    bee: "🐝",
                    seedling: "🌱",
                    droplet: "💧",
                    bulb: "💡",
                    calendar: "📆",
                    exclamation: "❗",
                    speech_balloon: "💬",
                },
            },
        };
    },
    methods: {
        insert(emoji) {
            this.selectedEmoji = emoji;
            this.$emit("emojiselected", emoji);
        },
    },
};
</script>

<style scoped lang="scss">
@import "src/assets/_index.scss";

.wrapper {
    position: relative;
    display: inline-block;
}

.regular-input {
    padding: 0.5rem 1rem;
    border-radius: 3px;
    border: 1px solid #ccc;
    width: 20rem;
    height: 12rem;
    outline: none;
}

.regular-input:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.emoji-invoker {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s;
}
.emoji-invoker:hover {
    transform: scale(1.1);
}
.emoji-invoker > svg {
    fill: #b1c6d0;
}

.emoji-picker {
    position: absolute;
    z-index: 1;
    font-family: Montserrat;
    border: 1px solid #ccc;
    width: 15rem;
    height: 20rem;
    overflow: scroll;
    padding: 1rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    background: #fff;
    box-shadow: 1px 1px 8px #c7dbe6;
}
.emoji-picker__search {
    display: flex;
}
.emoji-picker__search > input {
    flex: 1;
    border-radius: 10rem;
    border: 1px solid #ccc;
    padding: 0.5rem 1rem;
    outline: none;
}
.emoji-picker h5 {
    margin-bottom: 0;
    color: #b1b1b1;
    text-transform: uppercase;
    font-size: 0.8rem;
    cursor: default;
}
.emoji-picker .emojis {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.emoji-picker .emojis:after {
    content: "";
    flex: auto;
}
.emoji-picker .emojis span {
    padding: 0.2rem;
    cursor: pointer;
    border-radius: 5px;
}
.emoji-picker .emojis span:hover {
    background: #ececec;
    cursor: pointer;
}

.emoji {
    font-size: 24px;
}

.heading {
    font-size: 14px;
    font-weight: bold;
}

.emoji-wrapper {
    margin-top: $medium;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.sub-label {
    font-size: 12px;
    color: #666666;
}
</style>
