<template>
    <div class="inner">
        <div class="chat-input">
            <div class="form-container">
                <div class="input-group send-message">
                    <input type="text" placeholder="Write a new message" v-model="newChatMessage" />
                    <v-btn class="send-button" tile large icon @click="sendMessage">
                        <v-icon>mdi-send</v-icon>
                    </v-btn>
                </div>
            </div>
        </div>
        <pulse-loader :loading="loading" color="#333"></pulse-loader>
        <div class="messages" v-if="!loading && messages.length > 0">
            <message
                v-for="message in messages"
                v-bind:key="message.message_id"
                :message="message"
            ></message>
        </div>
        <div class="no-messages" v-else-if="!loading">
            <div class="inner">
                <div class="icon">
                    <v-icon size="80">mdi-message-text-outline</v-icon>
                </div>

                <div class="message">No messages</div>
            </div>
        </div>
        <div style="min-height: 15rem"></div>
    </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import Message from "./Message";
export default {
    name: "Chat",
    components: { Message, PulseLoader },
    props: ["type", "refreshTime", "parentId"],
    data() {
        return {
            messages: [],
            loading: false,
            failed: false,
            newChatMessage: "",
            sendingMessage: false,
            interval: false,
        };
    },
    methods: {
        async getMessages(useLoading) {
            if (this.loading && useLoading) {
                return false;
            }

            if (useLoading) {
                this.loading = true;
            }

            var axios = require("axios");

            var url = "";
            var payload = {};

            if (this.type === "meeting") {
                url = "/meeting/chat/get";
                payload = {
                    meeting_id: this.parentId,
                };
            }

            if (this.type === "discussion") {
                url = "/discussion/chat/get";
                payload = {
                    discussion_id: this.parentId,
                };
            }

            try {
                var response = await axios.post(this.$root.baseApi + url, payload, {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Bearer " + this.$root.token,
                    },
                });

                if (response.status === 200 && response.data.data.messages) {
                    this.loading = false;
                    this.messages = response.data.data.messages;
                    this.sortMessages();
                } else {
                    this.failed = true;
                }
                this.loading = false;
            } catch (e) {
                this.loading = false;
                this.failed = true;
            }
        },
        async sendMessage() {
            if (this.sendingMessage || this.newChatMessage.length === 0) {
                return false;
            }

            this.sendingMessage = true;

            var axios = require("axios");

            var url = "";
            var payload = {};

            if (this.type === "meeting") {
                url = "/meeting/chat/new";
                payload = {
                    meeting_id: this.parentId,
                    message: this.newChatMessage,
                };
            }

            if (this.type === "discussion") {
                url = "/discussion/chat/new";
                payload = {
                    discussion_id: this.parentId,
                    message: this.newChatMessage,
                };
            }

            try {
                var response = await axios.post(this.$root.baseApi + url, payload, {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Bearer " + this.$root.token,
                    },
                });

                if (response.status === 201 && response.data.data) {
                    this.appendNewMessage(response.data.data);
                    this.sendingMessage = false;
                } else {
                    this.failed = true;
                }
                this.sendingMessage = false;
            } catch (e) {
                this.sendingMessage = false;
                this.failed = true;
            }
        },
        appendNewMessage: function (message) {
            this.newChatMessage = "";
            this.messages.unshift(message);
        },
        updateMessages: function () {
            this.getMessages(false);
        },
        sortMessages: function () {
            this.messages.reverse();
        },
    },
    async mounted() {
        this.getMessages(true);
    },
    async created() {
        let vm = this;

        if (!this.interval) {
            this.interval = setInterval(function () {
                vm.updateMessages();
            }, Number.parseInt(this.refreshTime));
        }
    },
    destroyed() {
        clearInterval(this.interval);
    },
};
</script>

<style scoped lang="scss">
@import "src/assets/_index.scss";

.inner {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.send-message {
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    flex-direction: row;
    padding: $medium 0;
}

.send-button {
    margin-left: $medium;
    color: #333333 !important;
}

.no-messages {
    flex-direction: row;
    align-items: center;
    display: block;
    justify-content: center;
    width: 100%;
    flex: 1;
    opacity: 0.6;
    padding: 40px;
    .inner {
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;

        .message {
            margin-top: $medium;
            font-size: 16px;
        }
    }
}
.v-spinner {
    flex: 1;
}
</style>
