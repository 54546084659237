<template>
    <div class="wrapper">
        <pulse-loader :loading="loading" color="#333"></pulse-loader>
        <div class="content" v-if="!loading && otherAttendee.attendee">
            <ProfileCard
                :allow-link="true"
                :full-name="otherAttendee.attendee.fullName"
                :profile-id="otherAttendee.attendee.profile_id"
                :title="getPositionString(otherAttendee.attendee)"
                :img-url="otherAttendee.attendee.profile_image"
            ></ProfileCard>
            <div class="pill">
                <div class="alert" v-if="showAlert"></div>
                {{ meetingStatus }}
            </div>
            <div class="meeting-title">{{ meeting.meeting_title }}</div>
            <div class="meeting-time">
                {{ meeting.start_time | moment("ddd D MMM") }},
                {{ meeting.start_time | moment("hh:mm") }} -
                {{ meeting.end_time | moment("hh:mma") }}
            </div>
            <WayFinding
                v-if="meeting.location.template_id && meeting.location.module_id"
                :template-id="meeting.location.template_id"
                :module-id="meeting.location.module_id"
            >
                <div class="location">{{ meeting.location.location_name }}</div>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11.5397 22.351C11.57 22.3685 11.5937 22.3821 11.6105 22.3915L11.6384 22.4071C11.8613 22.5294 12.1378 22.5285 12.3608 22.4075L12.3895 22.3915C12.4063 22.3821 12.43 22.3685 12.4603 22.351C12.5207 22.316 12.607 22.265 12.7155 22.1982C12.9325 22.0646 13.2388 21.8676 13.6046 21.6091C14.3351 21.0931 15.3097 20.3274 16.2865 19.3273C18.2307 17.3368 20.25 14.3462 20.25 10.5C20.25 5.94365 16.5563 2.25 12 2.25C7.44365 2.25 3.75 5.94365 3.75 10.5C3.75 14.3462 5.76932 17.3368 7.71346 19.3273C8.69025 20.3274 9.66491 21.0931 10.3954 21.6091C10.7612 21.8676 11.0675 22.0646 11.2845 22.1982C11.393 22.265 11.4793 22.316 11.5397 22.351ZM12 13.5C13.6569 13.5 15 12.1569 15 10.5C15 8.84315 13.6569 7.5 12 7.5C10.3431 7.5 9 8.84315 9 10.5C9 12.1569 10.3431 13.5 12 13.5Z"
                        fill="#0F172A"
                    />
                </svg>
            </WayFinding>
            <div class="location-container" v-else>
                <div class="location">{{ meeting.location.location_name }}</div>
            </div>
            <div class="description">{{ meeting.meeting_description }}</div>

            <div class="button-container" v-if="meetingStatus == 'Invitation Sent'">
                <div @click="showConfirm('cancelinvite')" class="button outline">Cancel Invite</div>
                <div class="button secondary" @click="showUpdateModal()">Suggest Update</div>
            </div>

            <div class="button-container" v-if="meetingStatus == 'Response Needed'">
                <div class="button outline" @click="showConfirm('decline')">Decline</div>
                <div class="button secondary" @click="showUpdateModal()">Suggest Update</div>
                <div class="button" @click="showConfirm('accept')">Accept</div>
            </div>

            <div class="button-container" v-if="meetingStatus == 'Confirmed'">
                <div class="button outline" @click="showConfirm('cancelmeeting')">
                    Cancel Meeting
                </div>
                <div class="button secondary" @click="showUpdateModal()">Suggest Update</div>
            </div>

            <div class="button-container" v-if="isVirtual && meetingStatus == 'Confirmed'">
                <div @click="joinVideo()" class="button secondary">Join Video Chat</div>
            </div>

            <chat type="meeting" refresh-time="5000" :parent-id="meetingId"></chat>
            <MeetingUpdate
                @closecard="showUpdateModal"
                @meetingupdated="meetingUpdated"
                :show-invite-card="showUpdate"
                :attendee="otherAttendee.attendee"
                :meeting="meeting"
            ></MeetingUpdate>
            <v-dialog />
        </div>
    </div>
</template>

<script>
import ProfileCard from "../../components/Onboarding/ProfileCard";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import Chat from "../../components/Chat/Chat";
import MeetingUpdate from "../../components/Invite/MeetingUpdate";
import WayFinding from "../../components/WayFinding.vue";

export default {
    name: "Meeting",
    components: { MeetingUpdate, Chat, ProfileCard, PulseLoader, WayFinding },
    data() {
        return {
            meetingId: this.$route.params.id,
            meeting: {},
            loading: false,
            failed: false,
            meetingStatus: "",
            otherAttendee: {},
            myself: {},
            ready: false,
            showUpdate: false,
            isVirtual: false,
        };
    },
    methods: {
        joinVideo: function () {
            let path = `/dashboard/meeting/${this.meetingId}/virtual`;

            let urlParams = new URLSearchParams({
                internalReference: this.$root.internalReference,
                eventCode: this.$root.eventCode,
            });
            let title = `Video with ${this.otherAttendee.attendee.first_name}`;
            if (this.$root.isApp) {
                this.$root.entegyCallbacks.changeRoute(path, title, urlParams, true);
                return;
            }

            this.$router.push(path);
        },
        loadMeeting: async function () {
            if (this.loading) {
                return false;
            }

            this.loading = true;

            var axios = require("axios");

            try {
                var response = await axios.post(
                    this.$root.baseApi + "/meeting/get",
                    {
                        meeting_id: this.meetingId,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Bearer " + this.$root.token,
                        },
                    }
                );

                window.console.log(response);

                if (response.status == 200 && response.data.data) {
                    this.meeting = response.data.data;
                } else {
                    this.failed = true;
                }
                this.loading = false;
            } catch (e) {
                this.loading = false;
                this.failed = true;
            }
        },
        setAttendees: function () {
            let myProfileId = this.$root.attendee.profile_id;
            let vm = this;

            let participants = JSON.parse(JSON.stringify(this.meeting.participants));

            participants.forEach(function (participant) {
                if (participant.attendee.profile_id !== myProfileId) {
                    participant.attendee.fullName =
                        participant.attendee.first_name + " " + participant.attendee.last_name;
                    participant.attendee.positionString = vm.getPositionString(
                        participant.attendee
                    );
                    vm.otherAttendee = participant;
                } else {
                    vm.myself = participant;
                }
            });
        },
        setMeetingStatus: function () {
            let status = "";

            // Invitation sent
            if (this.myself.has_accepted && !this.otherAttendee.has_accepted) {
                status = "Invitation Sent";
                this.showAlert = false;
            }

            // Confirmed
            if (this.myself.has_accepted && this.otherAttendee.has_accepted) {
                status = "Confirmed";
                this.showAlert = false;
            }

            // Response needed
            // either you're invited
            if (
                !this.myself.is_inviter &&
                !this.myself.has_accepted &&
                this.otherAttendee.has_accepted
            ) {
                status = "Response Needed";
                this.showAlert = true;
            }

            // host and other attendee has accepted but you haven't
            // ie. you created the meeting, and the other attendee made a change
            if (
                this.myself.is_inviter &&
                !this.myself.has_accepted &&
                this.otherAttendee.has_accepted
            ) {
                status = "Response Needed";
                this.showAlert = true;
            }

            if (this.meeting.is_cancelled) {
                status = "Cancelled";
                this.showAlert = false;
            }

            this.meetingStatus = status;
        },
        getPositionString(attendee) {
            let returnString = "";
            if (attendee.position) {
                returnString += attendee.position;
            }

            if (attendee.organisation) {
                if (returnString.length > 0) {
                    returnString += ", ";
                }

                returnString += attendee.organisation;
            }

            return returnString;
        },
        async cancelMeeting() {
            if (this.loading) {
                return false;
            }

            this.loading = true;

            var axios = require("axios");

            try {
                var response = await axios.post(
                    this.$root.baseApi + "/meeting/update",
                    {
                        meeting_id: this.meetingId,
                        is_cancelled: true,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Bearer " + this.$root.token,
                        },
                    }
                );

                window.console.log(response);

                if (response.status == 200 && response.data.data) {
                    this.meeting = response.data.data;
                } else {
                    this.failed = true;
                }
                this.loading = false;
            } catch (e) {
                this.loading = false;
                this.failed = true;
            }
        },
        async acceptMeeting() {
            if (this.loading) {
                return false;
            }

            this.loading = true;

            var axios = require("axios");

            try {
                var response = await axios.post(
                    this.$root.baseApi + "/meeting/update",
                    {
                        meeting_id: this.meetingId,
                        has_accepted: true,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Bearer " + this.$root.token,
                        },
                    }
                );

                window.console.log(response);

                if (response.status == 200 && response.data.data) {
                    this.meeting = response.data.data;
                } else {
                    this.failed = true;
                }
                this.loading = false;
            } catch (e) {
                this.loading = false;
                this.failed = true;
            }
        },
        showConfirm(type) {
            if (type === "cancelinvite") {
                this.$modal.show("dialog", {
                    title: "Cancel Invite",
                    text: "Are you sure you wish to cancel this invitation?",
                    buttons: [
                        {
                            title: "No",
                        },
                        {
                            title: "Yes",
                            handler: () => {
                                this.cancelMeeting();
                            },
                        },
                    ],
                });
            }

            if (type === "decline") {
                this.$modal.show("dialog", {
                    title: "Decline Invite",
                    text: "Are you sure you wish to decline this invitation?",
                    buttons: [
                        {
                            title: "No",
                        },
                        {
                            title: "Yes",
                            handler: () => {
                                this.cancelMeeting();
                            },
                        },
                    ],
                });
            }

            if (type === "cancelmeeting") {
                this.$modal.show("dialog", {
                    title: "Cancel Meeting",
                    text: "Are you sure you wish to cancel this meeting?",
                    buttons: [
                        {
                            title: "No",
                        },
                        {
                            title: "Yes",
                            handler: () => {
                                this.cancelMeeting();
                            },
                        },
                    ],
                });
            }

            if (type === "accept") {
                this.$modal.show("dialog", {
                    title: "Accept Invitation",
                    text: "Are you sure you wish to accept this invitation?",
                    buttons: [
                        {
                            title: "No",
                        },
                        {
                            title: "Yes",
                            handler: () => {
                                this.acceptMeeting();
                            },
                        },
                    ],
                });
            }
        },
        showUpdateModal() {
            this.showUpdate = !this.showUpdate;
        },
        meetingUpdated(e) {
            this.meeting = e.meeting;
        },
    },
    async mounted() {
        document
            .querySelector(":root")
            .style.setProperty("--colour-primary", this.$root.event.settings.primary_colour);
        document
            .querySelector(":root")
            .style.setProperty("--colour-secondary", this.$root.event.settings.secondary_colour);
        await this.loadMeeting();
    },
    watch: {
        meeting: function () {
            this.isVirtual = this.meeting.location.is_virtual;
            this.setAttendees();
            this.setMeetingStatus();
        },
    },
};
</script>

<style scoped lang="scss">
@import "src/assets/_index.scss";

.meeting-title {
    margin-top: $medium;
    font-size: 24px;
    font-weight: bold;
}

.meeting-time,
.location {
    font-weight: bold;
}

.meeting-time {
    margin-top: $small;
}

.location {
    opacity: 0.7;
}

.description {
    line-height: 1.4;
    margin-top: $medium;
}

.pill {
    align-self: flex-start !important;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    padding: 3px 5px;
    border-radius: 5px;
    background: #d2d2d2;
    text-align: center;
    margin-top: $small;

    .alert {
        width: 8px;
        height: 8px;
        background: #ff0d00;
        border-radius: 50%;
        margin-right: 4px;
    }
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.button-container {
    display: flex;

    .button {
        flex: 1 1 auto;
        width: auto;
        margin-left: $small;

        &:first-child {
            margin-left: 0;
        }

        &.outline {
            background: transparent;
            border: 1px solid #333333;
            color: #333333;
        }

        &.secondary {
            background: #666;
        }
    }
}

.wayfinding-button {
    display: flex;
    padding: $small;
    border-radius: 8px;
    border: 1px solid #d1d5db;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

.wayfinding-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: $medium;
    padding-block: $small;
}

.location-container {
    margin-top: $medium;
}
</style>
