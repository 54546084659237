import "core-js/stable";
import "regenerator-runtime/runtime";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import EntegyApi from "./router/EntegyApi";
import moment from "moment/src/moment";
import vmodal from "vue-js-modal";
import VueLazyload from "vue-lazyload";

Vue.use(vmodal, { dialog: true });
Vue.use(VueLazyload);

window.moment = moment;

// require('es6-promise').polyfill();

Vue.config.productionTip = false;

const ENTEGY_MODULE_ID = 1;
const FRONTEND_URL = process.env.VUE_APP_FRONTEND_URL;

let entegyApi = new EntegyApi(FRONTEND_URL).withModuleId(ENTEGY_MODULE_ID);

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const apiURL = urlParams.get("apiUrl");
const localStorageApi = window.localStorage.getItem("baseAPI");

// Are we on the Entegy mobile app
const isApp = urlParams.get("isApp") === "true" ? true : false;

let baseAPI = null;
if (apiURL) {
    baseAPI = apiURL;
    window.localStorage.setItem("baseAPI", apiURL);
} else if (localStorageApi) {
    baseAPI = localStorageApi;
} else {
    baseAPI = process.env.VUE_APP_API_URL;
}

const state = {
    attendee: null,
    token: null,
    event: null,
    baseApi: baseAPI,
    isApp: isApp,
    entegyCallbacks: entegyApi,
    eventCode: null,
    internalReference: null,
};

Vue.use(require("vue-moment"));
Vue.filter("formatTime", function (value, format) {
    format = format || "hh:mma";
    return moment.utc(value).format(format);
});

new Vue({
    router,
    render: (h) => h(App),
    vuetify,
    data: state,
}).$mount("#app");

if (/Android/.test(navigator.appVersion)) {
    window.addEventListener("resize", function () {
        if (
            document.activeElement.tagName == "INPUT" ||
            document.activeElement.tagName == "TEXTAREA"
        ) {
            window.setTimeout(function () {
                document.activeElement.scrollIntoViewIfNeeded();
            }, 0);
        }
    });
}
