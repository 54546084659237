<template>
    <div class="wrapper-inner" id="discussions">
        <div>
            <div class="top-bar">
                <h1>
                    Discussion <br />
                    Groups
                </h1>
                <div class="new-button" @click="createNewDiscsussion">
                    <div class="button-inner">
                        <div class="button-icon">
                            <svg
                                version="1.2"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                overflow="visible"
                                preserveAspectRatio="none"
                                viewBox="0 0 24 24"
                                width="18"
                                height="18"
                            >
                                <g>
                                    <defs></defs>
                                    <rect
                                        display="none"
                                        fill="#FFA400"
                                        width="24"
                                        height="24"
                                        style="fill: rgb(18, 18, 18)"
                                        vector-effect="non-scaling-stroke"
                                    />
                                    <rect
                                        id="_x3C_Slice_x3E__109_"
                                        display="none"
                                        fill="none"
                                        width="24"
                                        height="24"
                                        style="fill: rgb(18, 18, 18)"
                                        vector-effect="non-scaling-stroke"
                                    />
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M21,0H3C1.343,0,0,1.343,0,3v12c0,1.657,1.343,3,3,3h6l-2,6l9-6h5 c1.657,0,3-1.343,3-3V3C24,1.343,22.657,0,21,0z M18,10h-5v5h-2v-5H6V8h5V3h2v5h5V10z"
                                        style="fill: rgb(18, 18, 18)"
                                        vector-effect="non-scaling-stroke"
                                    />
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div class="button-text">Create New</div>
                </div>
            </div>
            <h4 class="subtitle">Create or join group discussions with other attendees</h4>
        </div>

        <pulse-loader :loading="loading" color="#333"></pulse-loader>
        <div v-if="!loading && filteredDiscussions.length === 0" class="no-discussions">
            <div class="inner">
                <div class="icon">
                    <svg
                        version="1.2"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        overflow="visible"
                        preserveAspectRatio="none"
                        viewBox="0 -0.9947064485081789 53 53"
                        width="95"
                        height="95"
                    >
                        <g>
                            <g xmlns:default="http://www.w3.org/2000/svg">
                                <g>
                                    <path
                                        d="M0,42h18l5,9l5-9h18V4H0V42z M2,6.1h42v34H27l-4,7l-4-7H2V6.1z M15,0v2h36v33h-3v2h5V0H15z M12,18.2c-2.2,0-4,1.8-4,4    s1.8,4,4,4c2.2,0,4-1.8,4-4S14.2,18.2,12,18.2z M12,24.2c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S13.1,24.2,12,24.2z M23,26.2    c2.2,0,4-1.8,4-4s-1.8-4-4-4c-2.2,0-4,1.8-4,4S20.8,26.2,23,26.2z M23,20.2c1.1,0,2,0.9,2,2s-0.9,2-2,2c-1.1,0-2-0.9-2-2    S21.9,20.2,23,20.2z M34,26.2c2.2,0,4-1.8,4-4s-1.8-4-4-4c-2.2,0-4,1.8-4,4S31.8,26.2,34,26.2z M34,20.2c1.1,0,2,0.9,2,2    s-0.9,2-2,2s-2-0.9-2-2S32.9,20.2,34,20.2z"
                                        style="fill: rgb(18, 18, 18)"
                                        vector-effect="non-scaling-stroke"
                                    />
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>
                <div class="message">No Discussion Groups</div>
            </div>
        </div>
        <div v-else-if="!loading" class="discussion-container">
            <div class="search-container" v-if="false">
                <input
                    placeholder="Search by name or topic"
                    v-model="searchFilter"
                    type="text"
                    class="search"
                />
                <div class="icon">
                    <v-icon>mdi-magnify</v-icon>
                </div>
            </div>
            <DiscussionCard
                v-for="discussion in filteredDiscussions"
                :discussion="discussion"
                v-bind:key="discussion.discussion_id"
            ></DiscussionCard>
        </div>
        <discussion-invite :show-invite-card="showDiscussion"></discussion-invite>
    </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import DiscussionCard from "../../../components/DiscussionCard";
import DiscussionInvite from "../../../components/Invite/DiscussionInvite";

export default {
    name: "Discussion",
    components: { DiscussionInvite, DiscussionCard, PulseLoader },
    data() {
        return {
            searchFilter: "",
            discussions: [],
            loading: false,
            failed: false,
            showDiscussion: false,
        };
    },
    methods: {
        getDiscussions: async function () {
            if (this.loading) {
                return false;
            }

            this.loading = true;

            var axios = require("axios");

            try {
                var response = await axios.post(
                    this.$root.baseApi + "/discussion/all",
                    {},
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Bearer " + this.$root.token,
                        },
                    }
                );

                if (response.status === 200 && response.data.data.discussions) {
                    this.discussions = response.data.data.discussions;
                } else {
                    this.failed = true;
                }
                this.loading = false;
            } catch (e) {
                this.loading = false;
                this.failed = true;
            }
        },
        createNewDiscsussion: function () {
            this.showDiscussion = !this.showDiscussion;
        },
    },
    computed: {
        filteredDiscussions: function () {
            if (this.discussions.length === 0) {
                return [];
            }

            const discussions = JSON.parse(JSON.stringify(this.discussions));

            const myProfileId = this.$root.attendee.profile_id;

            let returnDiscussions = discussions.filter(function (discussion) {
                if (!discussion.is_cancelled) return true;

                if (!discussion.hasOwnProperty("participants")) {
                    return false;
                }

                for (var i = 0; i < discussion.participants.length; i++) {
                    let participant = discussion.participants[i];
                    if (
                        myProfileId === participant.attendee.profile_id &&
                        participant.has_accepted
                    ) {
                        return true;
                    }
                }

                return false;
            });

            window.console.log(returnDiscussions);

            return returnDiscussions;
        },
    },
    mounted() {
        this.getDiscussions();
    },
};
</script>

<style lang="scss">
@import "src/assets/_index.scss";

.v-spinner {
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: 1s all ease-in-out;
}

.no-discussions {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

    .inner {
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon svg {
            margin-top: $large;
            height: 6rem;
            width: 6rem;
            opacity: 0.1;
        }

        .message {
            margin-top: $large;
            opacity: 0.5;
            font-weight: bold;
        }
    }
}

#discussions {
    .subtitle {
        margin-top: $medium;
    }

    .search-container {
        min-height: 3rem !important;
        border-radius: 44px;
        width: 100%;
        border: 1px solid #8c8c8c;
        background: #eee;
        margin-top: $medium;
        display: flex;
        align-items: center;

        input {
            width: 90%;
            padding: 12px $medium;
            font-size: 14px;
            color: #121212;
        }
    }

    .top-bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .new-button {
        margin-top: $small;
        display: flex;
        flex-direction: column;
        align-items: center;

        .button-inner {
            display: flex;
            flex: 0;
            min-width: 2.5rem;
            min-height: 2.5rem;
            border-radius: 50%;
            background: #dadada;
            align-items: center;
            justify-content: center;

            .button-icon {
                flex: 0;
                height: 18px;
                fill: #121212;
                opacity: 0.6;
            }
        }

        .button-text {
            margin-top: $small;
            opacity: 0.6;
        }
    }
}

.discussion-container {
    padding-bottom: 7rem;
}
</style>
