<template>
    <div class="meeting-invite overlay" :class="showInviteCard ? '' : 'hidden'">
        <div class="inner">
            <div class="header">
                <div class="heading fill">Meeting Invitation</div>
                <div class="close-btn" @click="closeAndReset()">
                    Cancel Invite
                    <v-icon size="22">mdi-close</v-icon>
                </div>
            </div>
            <SelectRoom
                @updatedroom="roomSelected"
                :location="location"
                :type="type"
                capacity="2"
                :is-active="currentTab == 'location'"
                :key="componentKey"
                :attendee_id="attendee.profile_id"
            ></SelectRoom>
            <SelectTime
                @updatedtime="timeSelected"
                :is-active="currentTab == 'time'"
                :attendee="attendee"
                :location="location"
                :type="type"
                :key="componentKey"
            ></SelectTime>
            <MeetingDetail
                @sendrequest="createMeeting"
                :is-active="currentTab == 'message'"
                :start-time="startTime"
                :end-time="endTime"
                :is-saving="isSavingMeeting"
                :location="location"
                :attendee="attendee"
                :key="componentKey"
            ></MeetingDetail>
        </div>
    </div>
</template>

<script>
import SelectRoom from "./SelectRoom";
import SelectTime from "./SelectTime";
import MeetingDetail from "./MeetingDetail";

export default {
    name: "MeetingInvite",
    components: { MeetingDetail, SelectRoom, SelectTime },
    props: {
        attendee: Object,
        showInviteCard: Boolean,
    },
    data() {
        return {
            currentTab: "location",
            location: {},
            type: "meeting",
            startTime: "",
            endTime: "",
            title: "",
            description: "",
            isSavingMeeting: false,
            hasFailedSaving: false,
            meetingId: null,
            componentKey: 0,
        };
    },
    methods: {
        closeAndReset() {
            this.currentTab = "location";
            this.location = {};
            this.startTime = "";
            this.endTime = "";
            this.title = "";
            this.description = "";
            this.showInviteCard = !this.showInviteCard;
            this.componentKey += 1;
        },
        roomSelected(e) {
            this.location = e;
            this.currentTab = "time";
        },
        timeSelected(e) {
            this.startTime = e.startTime;
            this.endTime = e.endTime;
            this.currentTab = "message";
        },
        handleMeetingCreated() {
            if (!this.meetingId && !this.hasFailedSaving) {
                return false;
            }

            this.showInviteCard = !this.showInviteCard;

            let queryParams = new URLSearchParams({
                ...this.$route.query,
            });

            let path = `/dashboard/meeting/${this.meetingId}`;
            let title = `Meeting with ${this.attendee.first_name}`;
            if (this.$root.isApp) {
                this.$root.entegyCallbacks.changeRoute(path, title, queryParams);
                return;
            }

            this.$router.push(path);
        },
        createMeeting: async function (e) {
            if (this.isSavingMeeting) {
                return false;
            }

            this.title = e.title;
            this.description = e.description;

            this.isSavingMeeting = true;

            var axios = require("axios");

            try {
                var response = await axios.post(
                    this.$root.baseApi + "/meeting/new",
                    {
                        location_id: this.location.location_id,
                        start_time: this.startTime,
                        end_time: this.endTime,
                        meeting_title: this.title,
                        meeting_description: this.description,
                        target_profile: this.attendee.profile_id,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Bearer " + this.$root.token,
                        },
                    }
                );

                if (response.status === 201 && response.data.data.meeting_id) {
                    this.meetingId = response.data.data.meeting_id;
                } else {
                    this.hasFailedSaving = true;
                }
                this.isSavingMeeting = false;
            } catch (e) {
                this.isSavingMeeting = false;
                this.hasFailedSaving = true;
            }
        },
    },
    watch: {
        meetingId: function () {
            this.handleMeetingCreated();
        },
    },
};
</script>

<style scoped lang="scss">
@import "src/assets/_index.scss";

.inner {
    flex: 1;
    padding: $gutter-width;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

.meeting-invite {
    padding-bottom: 300px;
    overflow-y: scroll;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    background: white;
    z-index: 100;

    &.hidden {
        height: 0 !important;
        overflow: hidden !important;
        padding: 0 !important;
    }

    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.heading {
    font-size: 14px;
    font-weight: bold;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .close-btn {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row;
        font-size: 14px;
        font-weight: bold;
        opacity: 0.6;
    }
}
</style>
