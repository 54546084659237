<template>
    <div class="wrapper-inner">
        <h1>Discover Matches</h1>
        <div class="search-container">
            <input
                placeholder="Search by name or organisation"
                v-model="searchFilter"
                type="text"
                class="search"
            />
            <div class="icon">
                <v-icon>mdi-magnify</v-icon>
            </div>
        </div>
        <v-tabs grow>
            <v-tab>Matches</v-tab>
            <v-tab>All Attendees</v-tab>
            <v-tab-item class="profile-container">
                <ProfileList type="matches" :search-term="searchFilter"></ProfileList>
            </v-tab-item>
            <v-tab-item class="profile-container">
                <ProfileList type="all" :search-term="searchFilter" />
            </v-tab-item>
        </v-tabs>
    </div>
</template>

<script>
import ProfileList from "../../../components/ProfileList";
// import AllProfiles from '../AllProfiles';
export default {
    name: "Discover",
    components: { ProfileList },
    data() {
        return {
            searchFilter: "",
        };
    },
};
</script>

<style scoped lang="scss">
@import "src/assets/_index.scss";

@function colour($color-name) {
    @return var(--colour-#{$color-name});
}

.v-tabs {
    margin-top: $medium;
}
.search-wrapper {
    min-height: 3rem;
    padding-top: $small;
}
.search-container {
    min-height: 3rem !important;
    border-radius: 44px;
    width: 100%;
    background: #eee;
    margin-top: $medium;
    display: flex;
    align-items: center;

    input {
        width: 90%;
        padding: 12px $medium;
        font-size: 14px;
        color: #121212;
    }
}

.v-tab {
    text-transform: none;
    font-weight: bold;
    opacity: 0.6;

    &.v-tab--active {
        color: colour(primary);
        border-bottom: 3px solid colour(primary);
        opacity: 1;
    }
}

.profile-container {
    margin-top: $large;
}
</style>
