<template>
    <div>
        <MeetingCard
            v-if="meeting.meeting_id !== undefined"
            :meeting="meeting"
            v-bind:key="meeting.meeting_id"
        ></MeetingCard>
        <session-card
            v-if="meeting.session_id !== undefined"
            :meeting="meeting"
            v-bind:key="meeting.meeting_id"
        ></session-card>
    </div>
</template>

<script>
import SessionCard from "./SessionCard.vue";
import MeetingCard from "./MeetingCard.vue";
export default {
    components: { SessionCard, MeetingCard },
    name: "SessionOrMeetingCard",
    props: {
        meeting: Object,
    },
    data() {
        return {};
    },
    methods: {},
};
</script>

<style lang="scss"></style>
