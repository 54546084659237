<template>
    <div class="profile-card" @click="goToAttendee()">
        <div class="img">
            <img v-if="imgUrl" v-lazy="imgUrl" />
            <div v-else class="template-image">
                <span class="icon">
                    <v-icon size="30">mdi-account-outline</v-icon>
                </span>
            </div>
        </div>
        <div class="right-info">
            <div class="name">
                {{ fullName }}
            </div>
            <div class="title">
                {{ title }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProfileCard",
    props: ["imgUrl", "fullName", "title", "allowLink", "profileId", "target"],
    data() {
        let shouldAllowLink = this.allowLink ? true : false;
        return {
            shouldAllowLink: shouldAllowLink,
        };
    },
    methods: {
        goToAttendee() {
            if (!this.shouldAllowLink) {
                return;
            }

            if (this.target === "app" && this.$root.isApp) {
                this.goToSettings();
                return;
            }

            let urlParams = new URLSearchParams({
                ...this.$route.query,
            });

            let path = `/dashboard/profile/${this.profileId}`;

            if (this.$root.isApp) {
                this.$root.entegyCallbacks.changeRoute(path, this.fullName, urlParams);
                return;
            }

            let newUrlPath = `${path}?${urlParams.toString()}`;
            this.$router.push(newUrlPath);
        },

        goToSettings() {
            this.$root.entegyCallbacks.goToContent(240, 1);
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "src/assets/_index.scss";

.profile-card {
    display: flex;
    flex-direction: row;
    align-items: center;

    .img {
        width: 3.5rem;
        margin-right: $medium;

        img {
            width: 100%;
        }
    }

    .name {
        font-weight: bold;
    }

    .title {
        font-size: 14px;
    }
}

.template-image {
    min-height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: #dadada;
}
</style>
