<template>
    <div class="wrapper-inner">
        <pulse-loader :loading="loading" color="#333"></pulse-loader>
        <div class="secondary-button" v-if="!loading" v-on:click="toggleFilter">
            Filter Attendees
        </div>
        <VirtualList
            class="collection"
            :data-key="'profile_id'"
            :data-sources="filteredAttendees"
            :data-component="componentType"
        />
        <profile-filter
            :available-types="availableTypes"
            :show-filter="showFilter"
            :interests-filter="interestsFilter"
            :type-filter="typeFilter"
            @update:showFilter="toggleFilter"
            @update:interestsFilter="(value) => (interestsFilter = value)"
            @update:typeFilter="(value) => (typeFilter = value)"
        />
    </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import VirtualList from "vue-virtual-scroll-list";
import Attendee from "./Attendee.vue";
import ProfileFilter from "./ProfileFilter";

export default {
    name: "ProfileList",
    components: { Attendee, PulseLoader, VirtualList, ProfileFilter }, // eslint-disable-line vue/no-unused-components
    props: {
        type: String,
        searchTerm: String,
    },
    data() {
        return {
            componentType: Attendee,
            attendees: [],
            loading: true,
            showFilter: false,
            typeFilter: [],
            interestsFilter: [],
        };
    },
    methods: {
        toggleFilter: function () {
            this.showFilter = !this.showFilter;
        },
        getAttendees: async function () {
            this.loading = true;

            var axios = require("axios");

            try {
                let url = "";

                if (this.type === "matches") {
                    url = "/attendee/matches";
                } else if (this.type === "all") {
                    url = "/attendee/all";
                }

                var response = await axios.post(
                    this.$root.baseApi + url,
                    {},
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Bearer " + this.$root.token,
                        },
                    }
                );

                if (response.status == 200 && response.data.data.attendees) {
                    this.attendees = response.data.data.attendees;
                } else {
                    this.failed = true;
                }
                this.loading = false;
            } catch (e) {
                this.loading = false;
                this.failed = true;
            }
        },
    },
    computed: {
        filteredAttendees() {
            let vm = this;
            let attendees = this.attendees;

            if (this.type === "matches" && attendees && attendees.length > 0) {
                attendees = attendees.sort(function (a, b) {
                    return b.weighting - a.weighting;
                });
            }

            if (this.type === "all" && attendees && attendees.length > 0) {
                attendees = attendees.sort(function (a, b) {
                    if (a.last_name < b.last_name) {
                        return -1;
                    }
                    if (a.last_name < b.last_name) {
                        return 1;
                    }

                    return 0;
                });
            }

            return attendees.filter((attendee) => {
                // check if attendee matches search term
                const fullName = attendee.first_name + " " + attendee.last_name;
                const matchesSearchTerm =
                    !vm.searchTerm ||
                    fullName.toLowerCase().includes(vm.searchTerm.toLowerCase()) ||
                    !vm.searchTerm ||
                    attendee.organisation.toLowerCase().includes(vm.searchTerm.toLowerCase());

                // check if attendee matches type filter
                const matchesTypeFilter =
                    !vm.typeFilter ||
                    vm.typeFilter.length === 0 || // no filter or empty array
                    vm.typeFilter.includes(attendee.type); // filter matches

                // check if attendee matches interests filter
                const matchesInterestsFilter =
                    !vm.interestsFilter ||
                    vm.interestsFilter.length === 0 || // no filter or empty array
                    attendee.interests.some((interest) =>
                        vm.interestsFilter
                            .map((interest) => interest.label)
                            .includes(interest.interest_name)
                    ); // filter matches

                // return true if all filters match
                return matchesSearchTerm && matchesTypeFilter && matchesInterestsFilter;
            });
        },
        availableTypes: function () {
            let types = [];
            this.attendees.forEach(function (attendee) {
                if (types.indexOf(attendee.type) === -1) {
                    types.push(attendee.type);
                }
            });

            return types;
        },
    },
    mounted() {
        this.getAttendees();
    },
};
</script>

<style scoped lang="scss">
@import "src/assets/_index.scss";

@function colour($color-name) {
    @return var(--colour-#{$color-name});
}

.v-spinner {
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: 1s all ease-in-out;
    z-index: 10000;
    background: white;
}

.collection {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: scroll; /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
    margin-bottom: 7rem;
    max-height: 67vh;
}

.secondary-button {
    font-size: 0.8rem;
    border: 1px colour(primary) solid;
    color: colour(primary);
    padding: 0.7rem;
    border-radius: 10px;
    display: block;
    text-align: left;
    margin-bottom: 1.5rem;
}
</style>
