<template>
    <div class="discussion-card" @click="viewDiscussion">
        <div class="left">
            <div class="emoji">{{ discussion.emoji }}</div>
        </div>
        <div class="right">
            <div class="discussion-card__title">{{ discussion.title }}</div>
            <div class="discussion-card__topic">{{ discussion.interest.interest_name }}</div>
            <div class="discussion-card__location">{{ discussion.location.location_name }}</div>
            <div class="discussion-card__time">
                {{ discussion.start_time | formatTime("ddd D MMM") }},
                {{ discussion.start_time | formatTime("hh:mma") }} -
                {{ discussion.end_time | formatTime("hh:mma") }}
            </div>
        </div>

        <div class="select">
            <svg
                version="1.2"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                overflow="visible"
                preserveAspectRatio="none"
                viewBox="0 0 24 24"
                width="24"
                height="24"
            >
                <g>
                    <path
                        xmlns:default="http://www.w3.org/2000/svg"
                        d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"
                        vector-effect="non-scaling-stroke"
                    />
                </g>
            </svg>
        </div>
        <div class="pill" v-if="discussion.is_cancelled">
            <div class="alert"></div>
            Cancelled
        </div>
    </div>
</template>

<script>
export default {
    name: "DiscussionCard",
    props: {
        discussion: Object,
    },
    data() {
        return {};
    },
    methods: {
        viewDiscussion() {
            let queryParams = new URLSearchParams({
                ...this.$route.query,
            });

            let path = `/dashboard/discussion/${this.discussion.discussion_id}`;
            let title = `Discussion - ${this.discussion.title}`;
            if (this.$root.isApp) {
                this.$root.entegyCallbacks.changeRoute(path, title, queryParams);
                return;
            }

            this.$router.push(path);
        },
    },
    beforeMount() {},
};
</script>

<style lang="scss">
@import "src/assets/_index.scss";

.discussion-card {
    position: relative;
    margin-top: $medium;
    border: 1px solid #8c8c8c;
    padding: $medium;
    border-radius: $small;
    display: flex;
    flex-direction: row;

    .left {
        display: flex;
        flex: 0 0 3rem;
        width: 3rem;
    }

    .emoji {
        font-size: 1.5rem;
    }

    .discussion-card__title {
        font-weight: bold;
    }

    .discussion-card__topic {
        font-size: 14px;
        opacity: 0.5;
    }

    .discussion-card__location,
    .discussion-card__time {
        font-size: 14px;
    }

    .discussion-card__location {
        margin-top: $small;
    }

    .right {
        flex: 1 1 auto;
    }

    &:first-child {
        margin-top: $large;
    }

    .select {
        position: absolute;
        bottom: $medium;
        right: $medium;
        height: 24px;
        color: #121212;
        opacity: 0.5;
    }

    .pill {
        position: absolute;
        top: $medium;
        right: $medium;
        font-size: 11px;
        padding: 3px 5px;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        background: rgba(51, 51, 51, 0.2);
        .alert {
            width: 6px;
            height: 6px;
            background: #ff0d00;
            border-radius: 50%;
            margin-right: 4px;
        }
    }
}
</style>
