<template>
    <div class="profile-card" @click="goToAttendee()">
        <div class="img">
            <img v-if="attendee.profile_image" v-lazy="attendee.profile_image" />
            <div v-else class="template-image">
                <span class="icon">
                    <v-icon size="30">mdi-account-outline</v-icon>
                </span>
            </div>
        </div>
        <div class="right-content">
            <div class="name">{{ attendee.first_name }} {{ attendee.last_name }}</div>
            <div class="subheading">{{ getPositionString }} | {{ attendee.type }}</div>
            <div class="weighting-container" v-if="attendee.weighting !== 0">
                <div class="weighting-row">
                    <div class="weighting" :style="{ width: attendee.weighting + '%' }"></div>
                </div>
                <div class="weighting-info">
                    {{ attendee.weighting }}% Match: {{ commonInterests }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Attendee",
    props: {
        source: Object,
    },
    computed: {
        attendee: function () {
            return this.source;
        },
        getPositionString() {
            let returnString = "";
            if (this.attendee.position) {
                returnString += this.attendee.position.trim();
            }

            if (this.attendee.organisation) {
                if (returnString.length > 0) {
                    returnString += ", ";
                }

                returnString += this.attendee.organisation.trim();
            }

            return returnString;
        },
        commonInterests: function () {
            if (this.attendee.common_interests && this.attendee.common_interests.length > 0) {
                return this.attendee.common_interests.join(", ");
            } else {
                return "";
            }
        },
    },
    methods: {
        goToAttendee() {
            let queryParams = new URLSearchParams({
                ...this.$route.query,
            });

            let path = `/dashboard/profile/${this.attendee.profile_id}`;
            let fullName = this.attendee.first_name + " " + this.attendee.last_name;
            if (this.$root.isApp) {
                this.$root.entegyCallbacks.changeRoute(path, fullName, queryParams);
                return;
            }
            let newUrlPath = `${path}?${queryParams.toString()}`;
            this.$router.push(newUrlPath);
        },
    },
};
</script>

<style scoped lang="scss">
@import "src/assets/_index.scss";
.profile-card {
    display: flex;
    flex-direction: row;
    flex: 0 1;
    min-height: 5.5rem;
    margin-bottom: $medium;

    .subheading {
        font-size: 0.9rem;
    }

    .img {
        flex: 0 0 4.2rem;
        height: 4.2rem;
        margin-right: $medium;
        img {
            width: 100%;
        }
    }

    .right-content {
        display: flex;
        flex: 1;
        flex-direction: column;
        border-bottom: 1px solid rgba(102, 102, 102, 0.2);
    }

    .name {
        font-weight: bold;
    }

    .title {
        font-size: 14px;
    }

    .template-image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: #dadada;
    }

    .weighting-info {
        margin-top: $small;
        font-size: 12px;
        opacity: 0.6;
        margin-bottom: 4px;
        flex: 1;
    }

    .weighting-row {
        flex: 1;
        width: 50%;
        height: 4px;
        margin-top: 8px;
        border-radius: 2px;
        background: #eee;
    }

    .weighting {
        width: 100%;
        height: 100%;
        background: colour(secondary);
        border-radius: 4px;
    }
}
</style>
