<template>
    <div class="wrapper-no-flex">
        <h1 style="margin-bottom: 1rem">Your Profile</h1>
        <profile-card
            :full-name="attendeeFullName"
            :img-url="attendee.profile_image"
            :title="attendee.position"
        ></profile-card>
        <div class="form-container">
            <div class="input-group" v-if="!hideMeetWith">
                <label class="input-label">Who do you want to meet?<sup>*</sup></label>
                <input @focus="onFocusTag" type="text" v-model="wantToMeet" maxlength="50" />
                <label class="sublabel">{{ wantToMeet.length }}/50 Characters</label>
            </div>
            <div class="input-group">
                <label class="input-label">About you<sup>*</sup></label>
                <textarea v-model="bio"></textarea>
            </div>
        </div>

        <div
            class="button full-width"
            :class="!isComplete ? 'disabled' : ''"
            v-on:click="saveAndContinue()"
        >
            <pulse-loader :loading="loading" color="white"></pulse-loader>
            <span v-if="!loading">Next</span>
        </div>

        <div class="bottom-margin-fill"></div>
    </div>
</template>

<script>
import ProfileCard from "../../components/Onboarding/ProfileCard";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
    components: { PulseLoader, ProfileCard },
    data: function () {
        window.console.log(this.$root.attendee);
        return {
            attendee: this.$root.attendee,
            event: this.$root.event,
            token: this.$root.token,

            attendeeFullName: String,

            wantToMeet: "I want to meet...",
            bio: "",
            loading: false,
        };
    },
    mounted() {
        document
            .querySelector(":root")
            .style.setProperty("--colour-primary", this.$root.event.settings.primary_colour);
        document
            .querySelector(":root")
            .style.setProperty("--colour-secondary", this.$root.event.settings.secondary_colour);
        if (this.attendee) {
            this.attendeeFullName = this.attendee.first_name + " " + this.attendee.last_name;
        }
    },
    computed: {
        hideMeetWith() {
            return this.$root.event.settings.hideMeetWith === true;
        },
        isComplete() {
            return (
                this.wantToMeet.length > 0 &&
                this.wantToMeet !== "I want to meet..." &&
                this.wantToMeet !== "I want to meet "
            );
        },
    },

    methods: {
        onFocusTag: function () {
            if (this.wantToMeet == "I want to meet...") {
                this.wantToMeet = "I want to meet ";
            }
        },
        saveProfile: async function () {
            if (this.loading || !this.isComplete) {
                return false;
            }

            this.loading = true;

            var axios = require("axios");

            try {
                var response = await axios.post(
                    this.$root.baseApi + "/attendee/update",
                    {
                        configuration: {
                            is_visible: true,
                            bio: this.bio,
                            looking_to_meet: this.wantToMeet,
                        },
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Bearer " + this.$root.token,
                        },
                    }
                );

                if (response.status == 200 && response.data.data) {
                    this.$root.attendee = response.data.data;
                } else {
                    this.failed = true;
                }
                this.loading = false;
            } catch (e) {
                this.loading = false;
                this.failed = true;
            }
        },

        updateInterests: function () {
            if (this.failed) {
                // handle
                return;
            }

            if (this.$root.event.settings.interestsManagedExternally === true) {
                this.$router.push({
                    path: "/dashboard/panel/meetings",
                });

                return;
            }

            this.$router.push({
                path: "/dashboard/interestspopulate",
            });
        },

        saveAndContinue: async function () {
            await this.saveProfile();
            this.updateInterests();
        },
    },
};
</script>

<style lang="scss">
@import "src/assets/_index.scss";

.form-container {
    margin-top: $large;
}

.input-group {
    .input-label {
        display: block;
        font-weight: bold;
        font-size: 14px;
        margin-bottom: $small;
    }

    input[type="text"],
    textarea {
        width: 100%;
        min-height: 40px;
        padding: 0px 8px;
        border: 1px solid #8c8c8c;
        border-radius: 4px;
        background-color: #ffffff;
        background-size: cover;
        font-size: 14px;
        color: #121212;
        text-decoration: none solid rgb(18, 18, 18);
    }

    textarea {
        padding: 8px;
        min-height: 8rem;
    }

    .sublabel {
        margin-top: 4px;
        display: block;
        text-align: right;
        font-size: 12px;
    }
}

.button {
    span {
        color: white;
    }
}

.wrapper-no-flex {
    margin: 24px;
}

.bottom-margin-fill {
    min-height: 12rem;
}

sup {
    top: -0.1em;
}
</style>
