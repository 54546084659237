<template>
    <div class="meeting-detail" :class="isActive ? '' : 'hidden'">
        <h1>Add a personal message</h1>

        <div class="details-container">
            <div class="d-flex-row">
                <div class="icon">
                    <v-icon size="20">mdi-account</v-icon>
                </div>
                <div class="right">
                    <div class="name">{{ attendee.first_name }} {{ attendee.last_name }}</div>
                    <div class="position">{{ attendee.position }}, {{ attendee.organisation }}</div>
                </div>
            </div>
            <div class="d-flex-row">
                <div class="icon">
                    <v-icon size="20">mdi-map-marker</v-icon>
                </div>
                <div class="right">
                    {{ location.location_name }}
                </div>
            </div>
            <div class="d-flex-row">
                <div class="icon">
                    <v-icon size="20">mdi-clock-outline</v-icon>
                </div>
                <div class="right">
                    {{ startTime | formatTime("ddd D MMM") }},
                    {{ startTime | formatTime("hh:mm") }} - {{ endTime | formatTime("hh:mma") }}
                </div>
            </div>
        </div>

        <div class="form-container">
            <div class="input-group">
                <label class="input-label">Subject <sup>*</sup></label>
                <input type="text" v-model="title" maxlength="50" />
            </div>
            <div class="input-group">
                <label class="input-label">Message<sup>*</sup></label>
                <textarea v-model="description"></textarea>
            </div>
        </div>

        <div
            class="button full-width"
            :class="!isComplete ? 'disabled' : ''"
            @click="sendRequest()"
        >
            <pulse-loader :loading="isSaving" color="white"></pulse-loader>
            <span v-if="!isSaving">Send Meeting Request</span>
        </div>
    </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
    components: { PulseLoader },
    props: ["isActive", "startTime", "endTime", "location", "attendee", "isSaving"],
    name: "MeetingDetail",
    data() {
        return {
            title: "",
            description: "",
        };
    },
    methods: {
        sendRequest() {
            let title = this.title;
            let description = this.description;

            window.console.log("Here");

            this.$emit("sendrequest", { title, description });
        },
    },
    computed: {
        isComplete: function () {
            return this.title.length > 0 && this.description.length > 0;
        },
    },
    watch: {
        isActive: function () {
            this.title = "";
            this.description = "";
        },
    },
};
</script>

<style scoped lang="scss">
@import "src/assets/_index.scss";

.meeting-detail {
    margin-top: $medium;

    &.hidden {
        display: none;
    }

    .name {
        font-weight: bold;
    }

    .d-flex-row {
        display: flex;
        flex-direction: row;
        margin-bottom: $medium;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.form-container {
    margin-top: $medium;
}

.details-container {
    margin-top: $medium;
    background: #f3f3f3;
    padding: $gutter-width;
    border-radius: 10px;
}

.input-label {
    margin-top: $medium;
}

.icon {
    flex: 0 0 2.5rem;
}

.input-group textarea {
    padding: 8px;
    min-height: 5rem;
}

.right {
    flex: 1;
}

sup {
    top: -0.1em;
}

.v-spinner {
    display: none;
}
</style>
