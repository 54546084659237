<template>
    <div style="height: 100vh">
        <pulse-loader :loading="loading" color="#333"></pulse-loader>
        <div class="content" v-if="!loading && otherAttendee.attendee && !ended">
            <div class="remoteVideoContainer">
                <div v-if="!participantHasConnected" style="color: white; padding: 30px">
                    Other participant has not yet connected
                </div>
                <video autoplay playsinline id="remoteTrackVideo"></video>
            </div>
            <audio autoplay id="remoteTrackAudio"></audio>
            <div id="localTrack"></div>
            <div class="audio-controls">
                <div class="button-circle secondary" @click="toggleMute()">
                    <svg
                        v-if="!muted"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="#4A5568"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z"
                        />
                    </svg>
                    <svg
                        v-else
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="#4A5568"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M7 4a3 3 0 016 0v4a3 3 0 11-6 0V4zm4 10.93A7.001 7.001 0 0017 8a1 1 0 10-2 0A5 5 0 015 8a1 1 0 00-2 0 7.001 7.001 0 006 6.93V17H6a1 1 0 100 2h8a1 1 0 100-2h-3v-2.07z"
                            clip-rule="evenodd"
                        />
                    </svg>
                </div>
                <div class="button-circle hang-up" @click="endCall()">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white">
                        <path
                            d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"
                        />
                        <path
                            d="M16.707 3.293a1 1 0 010 1.414L15.414 6l1.293 1.293a1 1 0 01-1.414 1.414L14 7.414l-1.293 1.293a1 1 0 11-1.414-1.414L12.586 6l-1.293-1.293a1 1 0 011.414-1.414L14 4.586l1.293-1.293a1 1 0 011.414 0z"
                        />
                    </svg>
                </div>
                <div class="button-circle secondary" @click="toggleVideo()">
                    <svg
                        v-if="!videoDisabled"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="#4A5568"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                        />
                    </svg>
                    <svg
                        v-else
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="#4A5568"
                    >
                        <path
                            d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"
                        />
                    </svg>
                </div>
            </div>
        </div>
        <div class="wrapper" v-if="ended">
            <p class="meeting-title">
                You ended the video call with {{ otherAttendee.attendee.first_name }}
            </p>
            <p style="margin-top: 20px">
                You can click below to rejoin the call. Otherwise, feel free to close this window.
            </p>
            <div class="button" @click="rejoinCall()">Re-join Call</div>
        </div>
    </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import Twilio from "twilio-video";

export default {
    name: "VirtualMeeting",
    components: { PulseLoader },
    data() {
        return {
            meetingId: this.$route.params.id,
            meeting: {},
            muted: false,
            videoDisabled: false,
            ended: false,
            loading: false,
            failed: false,
            accessToken: null,
            otherAttendee: {},
            myself: {},
            ready: false,
            showUpdate: false,
            isVirtual: false,
            participantHasConnected: false,
            room: null,
        };
    },
    methods: {
        toggleMute() {
            if (!this.ready) return;

            this.muted = !this.muted;

            var localAudioTrack = Array.from(this.room.localParticipant.audioTracks.values())[0]
                .track;

            if (this.muted) {
                localAudioTrack.disable();
            } else {
                localAudioTrack.enable();
            }
        },
        toggleVideo() {
            if (!this.ready) return;

            this.videoDisabled = !this.videoDisabled;

            var localVideoTrack = Array.from(this.room.localParticipant.videoTracks.values())[0]
                .track;

            if (this.videoDisabled) {
                localVideoTrack.disable();
            } else {
                localVideoTrack.enable();
            }
        },
        endCall() {
            this.ended = true;

            this.room.disconnect();
        },
        rejoinCall() {
            this.ended = false;

            this.connectVideo();
        },
        loadMeeting: async function () {
            this.loading = true;

            var axios = require("axios");

            try {
                var response = await axios.post(
                    this.$root.baseApi + "/meeting/get",
                    {
                        meeting_id: this.meetingId,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Bearer " + this.$root.token,
                        },
                    }
                );

                window.console.log(response);

                if (response.status == 200 && response.data.data) {
                    this.meeting = response.data.data;
                } else {
                    this.failed = true;
                }
                this.loading = false;
            } catch (e) {
                this.loading = false;
                this.failed = true;
            }
        },
        loadVirtual: async function () {
            if (this.loading) {
                return false;
            }

            this.loading = true;

            var axios = require("axios");

            try {
                var response = await axios.post(
                    this.$root.baseApi + "/meeting/virtual",
                    {
                        meeting_id: this.meetingId,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Bearer " + this.$root.token,
                        },
                    }
                );

                window.console.log(response);

                if (response.status == 200 && response.data.token) {
                    this.accessToken = response.data.token;
                    this.connectVideo();
                } else {
                    this.failed = true;
                }
                this.loading = false;
            } catch (e) {
                this.loading = false;
                this.failed = true;
            }
        },
        setAttendees: function () {
            let myProfileId = this.$root.attendee.profile_id;
            let vm = this;

            let participants = JSON.parse(JSON.stringify(this.meeting.participants));

            participants.forEach(function (participant) {
                if (participant.attendee.profile_id !== myProfileId) {
                    participant.attendee.fullName =
                        participant.attendee.first_name + " " + participant.attendee.last_name;
                    participant.attendee.positionString = vm.getPositionString(
                        participant.attendee
                    );
                    vm.otherAttendee = participant;
                } else {
                    vm.myself = participant;
                }
            });
        },
        getPositionString(attendee) {
            let returnString = "";
            if (attendee.position) {
                returnString += attendee.position;
            }

            if (attendee.organisation) {
                if (returnString.length > 0) {
                    returnString += ", ";
                }

                returnString += attendee.organisation;
            }

            return returnString;
        },
        meetingUpdated(e) {
            this.meeting = e.meeting;
        },
        connectVideo() {
            var VueThis = this;
            var connectOptions = {
                // Comment this line to disable verbose logging.
                // logLevel: 'debug',
                audio: true,
                // VP8 simulcast enables the media server in a Small Group or Group Room
                // to adapt your encoded video quality for each RemoteParticipant based on
                // their individual bandwidth constraints. This has no utility if you are
                // using Peer-to-Peer Rooms, so you can comment this line.
                preferredVideoCodecs: [{ codec: "VP8", simulcast: true }],

                // Capture 720p video @ 24 fps.
                video: {
                    width: 1280,
                    height: 720,
                    frameRate: 24,
                    facingMode: "user",
                },
            };

            window.console.log("connecting to video");

            Twilio.connect(this.accessToken, connectOptions).then(function (room) {
                VueThis.room = room;
                // console.log('Successfully joined a Room: ', room);
                window.console.log("Successfully joined a Room: " + VueThis.meetingId);

                // Attach the Tracks of the Room's Participants.
                room.participants.forEach(function (participant) {
                    VueThis.participantConnected(participant);
                });
                // When a Participant joins the Room, log the event.
                room.on("participantConnected", function (participant) {
                    window.console.log("Participant connected" + participant);
                    VueThis.participantConnected(participant);
                });

                // When a Participant leaves the Room, detach its Tracks.
                room.on("participantDisconnected", function (participant) {
                    window.console.log("Participant '" + participant.identity + "' left the room");
                    VueThis.participantDisconnected(participant);
                });

                var localVideoTrack = Array.from(room.localParticipant.videoTracks.values())[0]
                    .track;
                let localMediaContainer = document.getElementById("localTrack");
                localMediaContainer.appendChild(localVideoTrack.attach());
                VueThis.ready = true;
            });
        },
        attachTrack(track) {
            // Attach the Participant's Track to the thumbnail.
            window.console.log("Attaching track", track);
            var video = document.getElementById("remoteTrackVideo");
            var audio = document.getElementById("remoteTrackAudio");

            if (track.kind === "video") {
                track.attach(video);
            } else {
                track.attach(audio);
            }
        },
        detachTrack(track, participant) {
            window.console.log("Detach track", track, participant);
            // // Detach the Participant's Track from the thumbnail.
            // const $media = $(`div#${participant.sid} > ${track.kind}`, $participants);
            // $media.css('opacity', '0');
            // track.detach($media.get(0));
            //
            // // If the detached Track is a VideoTrack that is published by the active
            // // Participant, then detach it from the main video as well.
            // if (track.kind === 'video' && participant === activeParticipant) {
            //   track.detach($activeVideo.get(0));
            //   $activeVideo.css('opacity', '0');
            // }
        },
        participantConnected(participant) {
            var VueThis = this;
            this.participantHasConnected = true;

            // Handle the TrackPublications already published by the Participant.
            participant.tracks.forEach((publication) => {
                VueThis.trackPublished(publication, participant);
            });

            // Handle theTrackPublications that will be published by the Participant later.
            participant.on("trackPublished", (publication) => {
                VueThis.trackPublished(publication, participant);
            });
        },
        participantDisconnected(participant) {
            window.console.log("participant disconnected", participant);
            this.participantHasConnected = false;
        },
        trackPublished(publication, participant) {
            var VueThis = this;
            // If the TrackPublication is already subscribed to, then attach the Track to the DOM.
            if (publication.track) {
                VueThis.attachTrack(publication.track, participant);
            }

            // Once the TrackPublication is subscribed to, attach the Track to the DOM.
            publication.on("subscribed", (track) => {
                VueThis.attachTrack(track, participant);
            });

            // Once the TrackPublication is unsubscribed from, detach the Track from the DOM.
            publication.on("unsubscribed", (track) => {
                VueThis.detachTrack(track, participant);
            });
        },
    },

    async mounted() {
        document
            .querySelector(":root")
            .style.setProperty("--colour-primary", this.$root.event.settings.primary_colour);
        document
            .querySelector(":root")
            .style.setProperty("--colour-secondary", this.$root.event.settings.secondary_colour);
        await this.loadVirtual();
        await this.loadMeeting();
    },
    watch: {
        meeting: function () {
            this.setAttendees();
        },
    },
    created() {
        document.addEventListener("beforeunload", this.room.disconnect());
    },
};
</script>

<style scoped lang="scss">
@import "src/assets/_index.scss";

.meeting-title {
    margin-top: $medium;
    font-size: 24px;
    font-weight: bold;
}

.meeting-time,
.location {
    font-weight: bold;
    margin-top: 5px;
}

.location {
    opacity: 0.7;
}

.description {
    line-height: 1.4;
    margin-top: $medium;
}

.pill {
    align-self: flex-start !important;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    padding: 3px 5px;
    border-radius: 5px;
    background: #a0aec0;
    text-align: center;
    margin-top: $small;

    .alert {
        width: 8px;
        height: 8px;
        background: #ff0d00;
        border-radius: 50%;
        margin-right: 4px;
    }
}

.content {
    height: 100vh;
    flex: 1;
    display: flex;
    flex-direction: column;
}

#localTrack {
    display: flex;
    align-items: center;
    min-height: 50px;
    background: black;
    width: 30%;
    height: auto;
    position: fixed;
    bottom: 120px;
    right: 5px;
    & video {
        width: 100%;
    }
}

.remoteVideoContainer {
    width: 100%;
    height: calc(100vh - 100px);
    background: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.audio-controls {
    background: #1a202c;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 20px 80px 30px 80px;
}
.button-circle {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    color: white;

    svg {
        height: 2rem;
    }

    &.hang-up {
        width: 3.8rem;
        height: 3.8rem;
        background: #e53e3e;
        border: none;
        color: white;
    }
}

.button-container {
    display: flex;

    .button {
        flex: 1 1 auto;
        width: auto;
        margin-left: $small;

        &:first-child {
            margin-left: 0;
        }

        &.outline {
            background: transparent;
            border: 1px solid #333333;
            color: #333333;
        }

        &.secondary {
            background: #666;
        }
    }
}
</style>
