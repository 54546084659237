<template>
    <div>
        <h1>Your Profile</h1>
        <div class="margin-top border-bottom">
            <profile-card
                :full-name="attendeeFullName"
                :img-url="attendee.profile_image"
                :title="attendee.position + ' | ' + attendee.type"
                target="app"
                :allow-link="true"
            ></profile-card>
        </div>

        <v-tabs grow>
            <v-tab>About You</v-tab>
            <v-tab v-if="this.$root.event.settings.interestsManagedExternally !== true"
                >Topics</v-tab
            >
            <v-tab-item class="profile-container">
                <div class="form-container">
                    <div class="input-group" v-if="!hideMeetWith">
                        <label class="input-label">Who do you want to meet?<sup>*</sup></label>
                        <input type="text" v-model="wantToMeet" maxlength="50" />
                        <label class="sublabel"
                            >{{ wantToMeet ? wantToMeet.length : 0 }}/50 Characters</label
                        >
                    </div>
                    <div class="input-group">
                        <label class="input-label">About you<sup>*</sup></label>
                        <textarea v-model="bio"></textarea>
                    </div>
                </div>

                <div class="saved" v-if="saved">Your profile has been saved</div>

                <div
                    class="button full-width"
                    :class="!isComplete ? 'disabled' : ''"
                    v-on:click="saveProfile()"
                >
                    <pulse-loader :loading="loading" color="white"></pulse-loader>
                    <span v-if="!loading">Save</span>
                </div>
                <div class="bottom-margin-fill"></div>
            </v-tab-item>
            <v-tab-item
                v-if="this.$root.event.settings.interestsManagedExternally !== true"
                class="profile-container"
            >
                <div class="interests-container">
                    <Interest
                        v-for="interest in interests"
                        :interest="interest"
                        :key="interest.interest_id"
                        @selected="updateInterest"
                    ></Interest>
                </div>

                <div
                    class="button full-width"
                    :class="selectedInterests.length < 3 ? 'disabled' : ''"
                    v-on:click="saveInterests()"
                >
                    <pulse-loader :loading="loading" color="white"></pulse-loader>
                    <span v-if="!loading">Save</span>
                </div>
                <div class="bottom-margin-fill"></div>
            </v-tab-item>
        </v-tabs>
    </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import Interest from "../../../components/Onboarding/Interest";
import ProfileCard from "../../../components/Onboarding/ProfileCard";

export default {
    components: { PulseLoader, Interest, ProfileCard },
    name: "Profile",
    data() {
        let interests = this.$root.event.interests;

        let interestsArray = [];

        if (this.$root.attendee.interests) {
            this.$root.attendee.interests.forEach(function (interest) {
                interestsArray.push(interest.interest_id);
            });
        }

        interests.map(function (interest) {
            if (interestsArray.includes(interest.interest_id)) {
                interest.selected = true;
            } else {
                interest.selected = false;
            }
        });

        return {
            attendee: this.$root.attendee,
            attendeeFullName: String,
            wantToMeet: this.$root.attendee.configuration.looking_to_meet,
            bio: this.$root.attendee.configuration.bio,
            loading: false,
            interests: interests,
            selectedInterests: [],
            saved: false,
        };
    },
    computed: {
        hideMeetWith: function () {
            return this.$root.event.settings.hideMeetWith === true;
        },
    },
    watch: {
        saved: function (newValue, prevValue) {
            if (prevValue === false && newValue === true) {
                let vm = this;
                setTimeout(function () {
                    vm.saved = false;
                }, 2000);
            }
        },
    },
    mounted() {
        if (this.attendee) {
            this.attendeeFullName = this.attendee.first_name + " " + this.attendee.last_name;
        }
    },
    methods: {
        isComplete() {
            return (
                (this.hideMeetWith || this.wantToMeet.length > 0) &&
                this.wantToMeet != "I want to meet..."
            );
        },
        saveProfile: async function () {
            if (this.loading || !this.isComplete) {
                return false;
            }

            this.loading = true;

            var axios = require("axios");

            try {
                var response = await axios.post(
                    this.$root.baseApi + "/attendee/update",
                    {
                        configuration: {
                            is_visible: true,
                            bio: this.bio,
                            looking_to_meet: this.wantToMeet,
                        },
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Bearer " + this.$root.token,
                        },
                    }
                );

                if (response.status == 200 && response.data.data) {
                    this.$root.attendee = response.data.data;
                    this.saved = true;
                } else {
                    this.failed = true;
                }
                this.loading = false;
            } catch (e) {
                this.loading = false;
                this.failed = true;
            }
        },
        updateInterest(data) {
            let interest = this.interests.filter(
                (interest) => interest.interest_id === data.interest.interest_id
            )[0];
            interest.selected = data.newSelection;

            this.selectedInterests = this.filterSelected(this.interests);
        },
        filterSelected(data) {
            window.console.log(data.filter((item) => item.selected));
            return data.filter((item) => item.selected);
        },
        saveInterests: async function () {
            this.loading = true;

            var axios = require("axios");

            try {
                var response = await axios.post(
                    this.$root.baseApi + "/attendee/update",
                    {
                        interests: this.selectedInterests,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Bearer " + this.$root.token,
                        },
                    }
                );

                if (response.status == 200 && response.data.data) {
                    this.$root.attendee = response.data.data;
                } else {
                    this.failed = true;
                }
                this.loading = false;
            } catch (e) {
                this.loading = false;
                this.failed = true;
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import "src/assets/_index.scss";

@function colour($color-name) {
    @return var(--colour-#{$color-name});
}

.saved {
    margin-top: 0.5rem;
    color: rgb(3, 77, 3);
    padding: 1rem;
    background: rgb(230, 255, 230);
}

.interests-container {
    margin-top: $large;
}

.margin-top {
    margin-top: $large;
}

.border-bottom {
    padding-bottom: $medium;
    border-bottom: 2px solid #ddd;
}
</style>
