<template>
    <div class="meeting-invite overlay" :class="showInviteCard ? '' : 'hidden'">
        <div class="inner">
            <div class="header">
                <div class="heading fill">New Discussion</div>
                <div class="close-btn" @click="closeAndReset()">
                    Cancel
                    <v-icon size="22">mdi-close</v-icon>
                </div>
            </div>
            <SelectRoom
                @updatedroom="roomSelected"
                :location="location"
                :type="type"
                capacity="2"
                :is-active="currentTab == 'location'"
                :key="componentKey"
                :attendee_id="$root.attendee.profile_id"
            ></SelectRoom>
            <SelectTimeDiscussion
                @updatedtime="timeSelected"
                :is-active="currentTab == 'time'"
                :attendee="attendee"
                :location="location"
                :type="type"
                :key="componentKey"
            ></SelectTimeDiscussion>
            <SelectInterest
                @selectedinterest="interestSelected"
                :is-active="currentTab == 'interest'"
                :key="componentKey"
            ></SelectInterest>
            <DiscussionDetail
                @sendrequest="createDiscussion"
                :is-active="currentTab == 'detail'"
                :key="componentKey"
                :location="location"
                :start-time="startTime"
                :end-time="endTime"
                :interest="interest"
                :is-saving="isSavingDiscussion"
            ></DiscussionDetail>
        </div>
    </div>
</template>

<script>
import SelectRoom from "./SelectRoom";
import SelectTimeDiscussion from "./SelectTimeDiscussion";
import SelectInterest from "./SelectInterest";
import DiscussionDetail from "./DiscussionDetail";

export default {
    name: "DiscussionInvite",
    components: { DiscussionDetail, SelectInterest, SelectTimeDiscussion, SelectRoom },
    props: {
        attendee: Object,
        showInviteCard: Boolean,
    },
    data() {
        return {
            currentTab: "location",
            location: {},
            type: "discussion",
            startTime: "",
            endTime: "",
            title: "",
            description: "",
            emoji: "",
            requireApproval: false,
            interest: "",
            isSavingDiscussion: false,
            hasFailedSaving: false,
            discussionId: null,
            componentKey: 0,
        };
    },
    methods: {
        closeAndReset() {
            this.componentKey += 1;
            this.currentTab = "location";
            this.location = {};
            this.startTime = "";
            this.endTime = "";
            this.title = "";
            this.description = "";
            this.showInviteCard = !this.showInviteCard;
        },
        roomSelected(e) {
            this.location = e;
            this.currentTab = "time";
        },
        timeSelected(e) {
            this.startTime = e.startTime;
            this.endTime = e.endTime;
            this.currentTab = "interest";
        },
        interestSelected(e) {
            this.interest = e;
            this.currentTab = "detail";
        },
        handleDiscussionCreated() {
            if (!this.discussionId && !this.hasFailedSaving) {
                return false;
            }

            this.showInviteCard = !this.showInviteCard;

            let queryParams = new URLSearchParams({
                ...this.$route.query,
            });

            let path = `/dashboard/discussion/${this.discussionId}`;
            let title = `Discussion - ${this.title}`;
            if (this.$root.isApp) {
                this.$root.entegyCallbacks.changeRoute(path, title, queryParams);
                return;
            }

            this.$router.push(path);
        },
        createDiscussion: async function (e) {
            if (this.isSavingDiscussion) {
                return false;
            }

            this.title = e.title;
            this.description = e.description;
            this.emoji = e.emoji;

            this.isSavingDiscussion = true;

            var axios = require("axios");

            try {
                var response = await axios.post(
                    this.$root.baseApi + "/discussion/new",
                    {
                        location_id: this.location.location_id,
                        start_time: this.startTime,
                        end_time: this.endTime,
                        title: this.title,
                        description: this.description,
                        emoji: this.emoji,
                        interest_id: this.interest.interest_id,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Bearer " + this.$root.token,
                        },
                    }
                );

                if (response.status === 201 && response.data.data.discussion_id) {
                    this.discussionId = response.data.data.discussion_id;
                } else {
                    this.hasFailedSaving = true;
                }
                this.isSavingDiscussion = false;
            } catch (e) {
                this.isSavingDiscussion = false;
                this.hasFailedSaving = true;
            }
        },
    },
    watch: {
        discussionId: function () {
            this.handleDiscussionCreated();
        },
    },
};
</script>

<style scoped lang="scss">
@import "src/assets/_index.scss";

.inner {
    flex: 1;
    padding: $gutter-width;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

.meeting-invite {
    position: fixed;
    padding-bottom: 300px;
    overflow-y: scroll;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    background: white;

    &.hidden {
        height: 0 !important;
        overflow: hidden !important;
        padding: 0 !important;
    }

    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.heading {
    font-size: 14px;
    font-weight: bold;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .close-btn {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row;
        font-size: 14px;
        font-weight: bold;
        opacity: 0.6;
    }
}
</style>
