<template>
    <div class="wrapper-no-flex">
        <h1>Your <br />interests</h1>
        <p class="subheading">Please select at least 3 topics</p>

        <div class="interests-container">
            <Interest
                v-for="interest in interests"
                :interest="interest"
                :key="interest.interest_id"
                @selected="updateInterest"
            ></Interest>
        </div>

        <div
            class="button full-width"
            :class="selectedInterests.length < 3 ? 'disabled' : ''"
            v-on:click="saveAndContinue()"
        >
            <pulse-loader :loading="loading" color="white"></pulse-loader>
            <span v-if="!loading">Done</span>
        </div>

        <div class="bottom-margin-fill"></div>
    </div>
</template>

<script>
import Interest from "../../components/Onboarding/Interest";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
    name: "OnboardingInterestspopulate",
    components: { PulseLoader, Interest },
    data() {
        let interests = this.$root.event.interests;

        interests.map(function (interest) {
            interest.selected = false;
        });

        return {
            interests: interests,
            loading: false,
            selectedInterests: [],
        };
    },
    methods: {
        updateInterest(data) {
            let interest = this.interests.filter(
                (interest) => interest.interest_id === data.interest.interest_id
            )[0];
            interest.selected = data.newSelection;

            this.selectedInterests = this.filterSelected(this.interests);
        },
        filterSelected(data) {
            window.console.log(data.filter((item) => item.selected));
            return data.filter((item) => item.selected);
        },
        saveProfile: async function () {
            this.loading = true;

            var axios = require("axios");

            try {
                var response = await axios.post(
                    this.$root.baseApi + "/attendee/update",
                    {
                        interests: this.selectedInterests,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Bearer " + this.$root.token,
                        },
                    }
                );

                if (response.status == 200 && response.data.data) {
                    this.$root.attendee = response.data.data;
                } else {
                    this.failed = true;
                }
                this.loading = false;
            } catch (e) {
                this.loading = false;
                this.failed = true;
            }
        },

        goToDashboard: function () {
            if (this.failed) {
                // handle
                return;
            }

            this.$router.push({
                path: "/dashboard/panel/meetings",
            });
        },

        saveAndContinue: async function () {
            if (this.loading || this.selectedInterests.length < 3) {
                return false;
            }

            await this.saveProfile();
            this.goToDashboard();
        },
    },
    async mounted() {
        document
            .querySelector(":root")
            .style.setProperty("--colour-primary", this.$root.event.settings.primary_colour);
        document
            .querySelector(":root")
            .style.setProperty("--colour-secondary", this.$root.event.settings.secondary_colour);
    },
};
</script>

<style scoped lang="scss">
@import "src/assets/_index.scss";

h1 {
    margin-bottom: 0;
}

.subheading {
    margin-top: 4px;
    margin-bottom: $medium;
}
</style>
